import React, { FC, useContext, useState, useEffect } from 'react';
import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { EApprovalContext, EApprovalTypes, IEApprovalContext, IMainStepProps } from '..';
import { EAlertType, EAlertTypeSchema } from '../../../../../entities/IAlert';
import { Row } from '../../../../Common/Row/Row';
import { SelectInput } from '../../../../Common/SelectInput/SelectInput';
import { EDropDownType } from '../../../../Common/DropDown/DropDown';
import { EContextList, EReferentials, IReferencial } from '../../../../../entities/IGlobal';
import { getReferentials } from '../../../../../actions/globalActions';
import { IValue } from '../../../../../entities/IPickers';
import { EUploaderFileFormat, IFileUploaderChangeEvent, Uploader } from '../../../../Common/Attachment/Uploader';
import { uploadAttachment } from '../../../../../actions/clustersActions';
import { Title } from '../../../Common/Styles';
import { BadgeWrapper, GreyBox } from '../common';
import { Badge, EBadgeColor, EBadgeShape, EBadgeSize } from '../../../../Common/Badge/Badge';
import { Button, EButtonTypeSchema } from '../../../../Common/Buttons/NewButton';
import { formDataEncodeAndAppendFile } from '../../../../../tools/files';
import { Alert } from '../../../../Common/Alert/Alert';
import IntlMessage, { useIntlMessage } from '../../../../Common/IntlMessage';

type GetReferentials = ReturnType<typeof getReferentials>;
type UploadAttachment = ReturnType<typeof uploadAttachment>;

const Wrapper = styled.div`
    width: 100%;
    > div {
        margin-bottom: 1rem;
    }
    > :last-child {
        margin: 0;
    }
`;

export const AttachmentInformationSponsoring: FC<IMainStepProps> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const dispatch = useDispatch();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);
    const [typeOptions, setTypeOptions] = useState<IReferencial[]>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        dispatch<GetReferentials>(getReferentials('', EReferentials.SponsoringFileType, EContextList.EApproval)).then(response => {
            setTypeOptions(response);
        });
        eApprovalContext.modifyData(eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.attachmentInformation?.[0]?.type || '', 'type', props.context, props.stepName, props.stepNumber, 0);
    }, []);

    const onChangeType = (index: number, option?: IValue) => {
        eApprovalContext.modifyData(option?.data, 'type', props.context, props.stepName, props.stepNumber, index);
    };

    const onFileChange = (e: IFileUploaderChangeEvent, index: number) => {
        setLoading(true);
        const file = e.rawFile;
        const form = new FormData();
        formDataEncodeAndAppendFile(form, 'attachment', file);
        dispatch<UploadAttachment>(uploadAttachment(form)).then(res => {
            eApprovalContext.modifyData({
                name: res.name,
                directUri: res.directUri,
                size: res.size,
                fileId: res.fileId
            }, 'attachment', props.context, props.stepName, props.stepNumber, index);
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        });
    };

    const onFileRemove = (index: number) => {
        eApprovalContext.modifyData(undefined, 'attachment', props.context, props.stepName, props.stepNumber, index);
    };

    const removeElement = (index: number) => {
        eApprovalContext.removeFromArrays(props.context, props.stepName, index);
    };

    const onAddItem = () => {
        eApprovalContext.modifyData('', 'type', props.context, props.stepName, props.stepNumber, eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.attachmentInformation?.length);
    };

    return (
        <Wrapper>
            <Alert
                withoutProgress
                alertId={1}
                withoutClose
                typeSchema={EAlertTypeSchema.SECONDARY}
                visible
                transparentRight
                type={EAlertType.ERROR}
                title={intlFormatMessage({ id: `eApproval.wizard.step3.${[EApprovalTypes.Sponsoring]}.alert.title` })}
            >
                <span dangerouslySetInnerHTML={{
                    __html: intlFormatMessage({ id: `eApproval.wizard.step3.${[EApprovalTypes.Sponsoring]}.alert.label` })
                }} />
            </Alert>
            <Row numberOfElements={1} noBorder>
                <Title><IntlMessage id={`eApproval.wizard.step3.${[EApprovalTypes.Sponsoring]}.title`} /></Title>
            </Row>
            {eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.attachmentInformation?.map((elem, index) => (
                <GreyBox horizontal>
                    {index > 0 &&
                        <BadgeWrapper>
                            <Badge icon={faTrash} shape={EBadgeShape.CIRCLE} color={EBadgeColor.RED} size={EBadgeSize.SMALL} onClick={() => removeElement(index)} />
                        </BadgeWrapper>
                    }
                    <SelectInput
                        value={elem?.type?.id ?
                            [{
                                key: elem?.type?.id,
                                text: elem?.type?.name,
                                data: elem?.type
                            }] : undefined}
                        onChange={option => onChangeType(index, option)}
                        inputLabel={<IntlMessage id={`eApproval.wizard.step3.${[EApprovalTypes.Sponsoring]}.type`} />}
                        options={typeOptions?.map(option => ({
                            key: option.id,
                            text: option.name,
                            data: option
                        }))}
                        type={EDropDownType.DEFAULT}
                    />
                    <div>
                        <Uploader
                            label={intlFormatMessage({ id: `eApproval.wizard.step3.${[EApprovalTypes.Sponsoring]}.upload` })}
                            files={elem?.attachment ? [{ fileId: elem?.attachment.fileId, fileName: elem?.attachment.name, fileSize: elem?.attachment.size }] : []}
                            fileFormats={[EUploaderFileFormat.JPEG, EUploaderFileFormat.PNG, EUploaderFileFormat.PDF, EUploaderFileFormat.DOCX]}
                            removeAction={() => onFileRemove(index)}
                            onFileChange={e => onFileChange(e, index)}
                        />
                    </div>
                </GreyBox>
            ))}
            <Button
                type="button"
                typeSchema={EButtonTypeSchema.TEXT}
                disabled={(eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.attachmentInformation || [])?.map(elem => (!!elem?.attachment?.directUri && !!elem?.type?.id)).includes(false)}
                onClick={onAddItem}
                leftIco={faPlus}
            >
                <IntlMessage id="eApproval.button.add-attachment" />
            </Button>
        </Wrapper>
    );
};
