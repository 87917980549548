import React, { createContext, FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { StepsPopup } from '../../../Common/StepsPopup/StepsPopup';
import { EPopupSizes, IUserBrief } from '../../../../entities/IGlobal';
import { ISingleCluster } from '../../../../entities/IClusters';
import { postPOAForm } from '../../../../actions/clustersActions';
import { ICompany } from '../../../../entities/ICompany';
import { useIntlMessage } from '../../../Common/IntlMessage';

type PostEApprovalForm = ReturnType<typeof postPOAForm>;

export interface IPOAContext {
    orgId: string;
    data: IPOAData;
    modifyData(value: any, field: string, stepNr: number);
}

export interface IPOASendData {
    companyId: string;
    delegatorId: string;
    delegatorCapacity: string;
    delegateId: string;
    delegateCapacity: string;
    declarationName: string;
    parentClusterId?: string;
}

export interface IPOAData {
    company: ICompany;
    delegator: IUserBrief;
    delegatorCapacity: string;
    delegate: IUserBrief;
    delegateCapacity: string;
    parentClusterId: string;
    declarationName: string;
    techValues?: {
        stepNumber: number;
    }
}

interface IProps {
    organizationId: string;
    organizationUrlName: string;
    isPopupShown: boolean;
    isHome?: boolean;
    setShowPopup(showContract: boolean);
}

export const EPOAContext = createContext<IPOAContext>(undefined);

export const POAWizard: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const { intlFormatMessage } = useIntlMessage();
    const history = useNavigate();
    const [isValid, setIsValid] = useState<boolean>(false);
    const [data, setData] = useState<IPOAData>(undefined);
    const [stepFinished, setStepFinished] = useState<boolean>(false);
    const [isFormFinnished, setIsFormFinnished] = useState<boolean>(false);
    const [newClusterData, setNewClusterData] = useState<ISingleCluster>(undefined);

    const modifyData = (value: string, field: string, stepNumber: number) => {
        if (field !== undefined) {
            setData(state => ({
                ...state,
                [field]: value,
                techValues: {
                    stepNumber
                }
            }));
        } else {
            setData(state => ({
                ...state,
                techValues: {
                    stepNumber
                }
            }));
        }
    };

    useEffect(() => {
        globalValidation(data?.techValues?.stepNumber);
    }, [data]);

    useEffect(() => {
        setData(undefined);
    }, []);

    const globalValidation = async (stepNumber: number) => {
        let schema = Yup.object().shape({
            company: stepNumber === 2 && Yup.object().shape({
                id: Yup.string().required()
            }),
            delegator: stepNumber === 2 && Yup.object().shape({
                id: Yup.string().required()
            }),
            delegatorCapacity: stepNumber === 2 && Yup.string().required(),
            delegate: stepNumber === 2 && Yup.object().shape({
                id: Yup.string().required()
            }),
            delegateCapacity: stepNumber === 2 && Yup.string().required(),
            declarationName: stepNumber === 2 && Yup.string().required()
        });
        const result = await schema.isValid(data);
        setIsValid(result);
    };

    const initState: IPOAContext = {
        orgId: props.organizationId,
        data,
        modifyData
    };

    const finishHandler = () => {
        setData(undefined);
        props.setShowPopup(false);
        if (isFormFinnished) {
            history(`/orgs/${props.organizationUrlName}/cluster/${newClusterData.id}/overview`);
            setIsFormFinnished(false);
        } else if (props.isHome) {
            history('/');
        }
    };

    const finishDataHandler = () => {
        let finishData: IPOASendData = {
            companyId: data.company.id,
            delegatorId: data.delegator.id,
            delegatorCapacity: data.delegatorCapacity.trim(),
            delegateId: data.delegate.id,
            delegateCapacity: data.delegateCapacity.trim(),
            declarationName: data.declarationName.trim()
        };
        return finishData;
    };

    const confirmHandler = () => {
        return dispatch<PostEApprovalForm>(postPOAForm(props.organizationId, finishDataHandler())).then((response) => {
            setStepFinished(true);
            setIsFormFinnished(true);
            setNewClusterData(response);
        }).catch(reason => {
            console.log(reason);
        });
    };

    const clearForm = () => {
        props.setShowPopup(false);
        setData(undefined);
    };

    return (
        <EPOAContext.Provider value={initState}>
            <StepsPopup
                showPopup={props.isPopupShown}
                size={EPopupSizes.BIG}
                title={intlFormatMessage({ id: 'poa.wizard.title.delegation' })}
                isFormValid={isValid}
                finishHandler={finishHandler}
                dirty
                confirmFinished={stepFinished}
                preventClosingOnClickingOutside
                clearForm={clearForm}
                confirmHandler={confirmHandler}
                confirmDisabled={false}
                steps={[
                    {
                        content: <Step1 />,
                        title: 'Principles'
                    },
                    {
                        content: <Step2 />,
                        title: 'General Information'
                    },
                    {
                        content: <Step3 />,
                        title: 'Great!'
                    }
                ]}
            />
        </EPOAContext.Provider>
    );
};