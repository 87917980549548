import React, { FC } from 'react';
import styled from 'styled-components';

import { colorStack } from '../../../../styleHelpers/colors';
import { getTimeFromNow } from '../../../../tools/date';
import { useIntlMessage } from '../../IntlMessage';

const DateWrapper = styled.span`
    color: ${colorStack.content};
`;

export const DateAgoType: FC<React.PropsWithChildren<unknown>> = props => {
    const { intlFormatMessage } = useIntlMessage();
    return (
        <DateWrapper>{intlFormatMessage({ id: 'global.dateAgo' }, { date: getTimeFromNow(props.children as string) })}</DateWrapper>
    );
};