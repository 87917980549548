import React, { useState, useCallback, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';

import { createNegotiationsCluster } from '../../../../actions/clustersActions';
import { useAlert } from '../../../../tools/hooks';
import { EAlertType } from '../../../../entities/IAlert';
import { StepsPopup } from '../../../Common/StepsPopup/StepsPopup';
import { EPopupSizes } from '../../../../entities/IGlobal';
import { Step1 } from './Step1';
import { ETemplateTypes, ISingleCluster, EClusterPrivacy, ETemplateKeys } from '../../../../entities/IClusters';
import { Confirm } from './Confirm';
import { useOrganization } from '../../../../tools/organizationHooks';
import { ISIngleLegalEntity } from '../../../../entities/ILegalEntities';
import { IState } from '../../../../reducers';
import { IClusterReducer } from '../../../../reducers/clustersReducer';
import { useIntlMessage } from '../../../Common/IntlMessage';

type CreateNegotiationsCluster = ReturnType<typeof createNegotiationsCluster>;

export interface IFormikData {
    formData: {
        cluster: {
            clusterTemplateId: string;
            clusterTemplateName: string;
            clusterTemplateKey: string;
            clusterTemplateColor: string;
        };
        legalEntity: ISIngleLegalEntity;
        privacy: EClusterPrivacy;
    };
    steps: number;
}

const initialValues = {
    formData: {
        cluster: undefined,
        legalEntity: undefined,
        privacy: EClusterPrivacy.Private
    },
    steps: 0
};

interface IProps {
    organizationId: string;
    organizationUrlName: string;
    isPopupShown: boolean;
    isHome?: boolean;
    setShowPopup(showContract: boolean);
}

export const AssetsManagementWizard: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const { intlFormatMessage } = useIntlMessage();
    const addAlert = useAlert();
    const { clusterTemplates } = useSelector<IState, IClusterReducer>(state => state.clusters);
    const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState<boolean>(true);
    const [stepFinished, setStepFinished] = useState<boolean>(false);
    const [newClusterData, setNewClusterData] = useState<ISingleCluster>(undefined);
    const { organization } = useParams<{ organization: string }>();
    const { currentOrganization } = useOrganization(organization);
    const [isFormFinnished, setIsFormFinnished] = useState<boolean>(false);
    const [leExistErr, setLeExistErr] = useState<boolean>(false);

    const closePopup = useCallback((resetForm) => {
        resetForm();
        props.setShowPopup(false);
        setStepFinished(false);
        if (isFormFinnished) {
            history(`/orgs/${props.organizationUrlName}/cluster/${newClusterData.id}/overview`);
            setIsFormFinnished(false);
        } else if (props.isHome) {
            history('/');
        }
    }, [props.organizationUrlName, newClusterData, props.isHome]);

    const onSubmit = useCallback((values: IFormikData) => {
        setIsConfirmButtonDisabled(true);
        return dispatch<CreateNegotiationsCluster>(createNegotiationsCluster(
            props.organizationId,
            {
                organizationUrlName: props.organizationUrlName,
                privacy: values.formData.privacy,
                organizationName: currentOrganization?.details?.name,
                clusterTemplateId: clusterTemplates?.filter(template => template.templateKey === ETemplateKeys.Negotiation)?.[0].id,
                legalEntity: values.formData.legalEntity

            }
        )).then(response => {
            setIsFormFinnished(true);
            setNewClusterData(response);
            setIsConfirmButtonDisabled(false);
            setLeExistErr(false);
            setStepFinished(true);
        }).catch(err => {
            try {
                if (JSON.parse(err)?.request?.ajaxData === null) {
                    addAlert(intlFormatMessage({ id: 'global.noInternetConnection' }), EAlertType.ERROR);
                } else {
                    setLeExistErr(true);
                }
            } catch {
                setLeExistErr(true);
            }
        });
    }, [dispatch, setIsConfirmButtonDisabled, props.organizationId, clusterTemplates]);

    const clearForm = useCallback((resetForm) => {
        resetForm();
        setStepFinished(false);
    }, []);

    return (
        <Formik<IFormikData>
            initialValues={initialValues}
            validationSchema={() => Yup.lazy(() => {
                return Yup.object().shape({
                    formData: Yup.object().shape({
                        legalEntity: Yup.object().required(),
                        privacy: Yup.string().required()
                    })
                });
            })}
            onSubmit={onSubmit}>
            {({ isValid, dirty, submitForm, resetForm, values }) => (
                <StepsPopup
                    showPopup={props.isPopupShown}
                    preventClosingOnClickingOutside
                    size={EPopupSizes.MEDIUM}
                    title={intlFormatMessage({ id: 'assetmanagement.wizard.title' })}
                    isFormValid={isValid && !!values.formData.legalEntity}
                    dirty={dirty}
                    confirmDisabled={isConfirmButtonDisabled}
                    finishHandler={() => closePopup(resetForm)}
                    confirmFinished={stepFinished}
                    wizardType={ETemplateTypes.Contract}
                    clearForm={() => clearForm(resetForm)}
                    confirmHandler={submitForm}
                    steps={[
                        {
                            title: intlFormatMessage({ id: 'assetmanagement.wizard.step1.investor' }),
                            content: <Step1 error={leExistErr} />
                        },
                        {
                            content: <Confirm clusterName={newClusterData?.presentation?.name} />
                        }
                    ]}
                />
            )}
        </Formik>
    );
};
