import React, { useCallback, useState, useEffect, FC } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';

import { TextComponent } from '../../../../Common/Inputs/TextComponent';
import { NewDesignDropdown } from '../../../../Common/CustomDropdown/NewDesignDropdown';
import { colorStack } from '../../../../../styleHelpers/colors';
import { getReferentials } from '../../../../../actions/globalActions';
import { EReferentials, EContextList, IReferencial, IUserBrief } from '../../../../../entities/IGlobal';
import { IFormData, ITypesWithStatuses } from '../../../../../entities/IValidation';
import { Delete } from '../../../../../components/Common/Icons';
import { UsersPicker } from '../../../../Common/Pickers/UsersPicker';
import { IValue } from '../../../../../entities/IPickers';
import { getTypesWithStatuses } from '../../../../../actions/validationActions';
import { EDropDownType } from '../../../../Common/DropDown/DropDown';
import { useIntlMessage } from '../../../../Common/IntlMessage';

type GetReferentials = ReturnType<typeof getReferentials>;
type GetTypesWithStatuses = ReturnType<typeof getTypesWithStatuses>;

const Wrapper = styled.div`
    border-radius: 4px;
    padding: 1rem;
    background: ${colorStack.white};
    width: 100%;
    margin: 0 0 1rem 0;
    position: relative;
`;

const DelRecipient = styled(Delete)`
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 1rem 0;
    > div {
        width: 45%;
    }
`;

interface IValidator {
    childId: string;
    technicalId: string;
    childName: string;
    type: number;
    picture: string;
    email: string;
    labelId: string;
    selectedStatusKey: string;
    stageId: number;
    role: {
        id: string,
        name: string,
        key: string
    };
    validatorType: {
        id: string,
        name: string,
        key: string
    }
}

interface IProps {
    validator: IValidator;
    stageIndex: number;
    data: IValidator[];
    validatorIndex: number;
    changeData(data: IValidator[]);
}

export const SingleValidator: FC<React.PropsWithChildren<IProps>> = props => {
    const formikContext = useFormikContext<IFormData>();
    const dispatch = useDispatch();
    const { intlFormatMessage } = useIntlMessage();
    const [roles, setRoles] = useState<IReferencial[]>(undefined);
    const [statuses, setStatuses] = useState<ITypesWithStatuses[]>(undefined);
    const [fullNameText, setFullNameText] = useState<IValue[]>(undefined);
    const [emailText, setEmailText] = useState<string>('');

    useEffect(() => {
        dispatch<GetReferentials>(getReferentials('', EReferentials.ApprovalLabel, EContextList.Platform)).then(response => {
            setRoles(response);
        });
        dispatch<GetTypesWithStatuses>(getTypesWithStatuses()).then(response => {
            setStatuses(response);
        });
    }, []);

    useEffect(() => {
        setFullNameText(props.validator?.childName?.length > 0 ? [{
            key: props.validator.childId, text: props.validator.childName, data: {
                firstName: props.validator.childName,
                lastName: '',
                jobTitle: '',
                picture: props.validator.picture,
                cabinet: '',
                id: props.validator.childId,
                emailContact: props.validator.email
            }
        }] : undefined);
        setEmailText(props.validator?.email?.length > 0 ? props.validator.email : '');
    }, [props.validator]);

    const handleChangeEmail = (value: string) => {
        setEmailText(value);
        formikContext.setFieldValue('stages', formikContext.values.stages.map((stage) => ({
            ...stage, validators: stage.validators.map((validator) => {
                if (validator.labelId === props.validator.technicalId) {
                    return ({
                        ...validator, principal: {
                            ...validator.principal, email: value
                        }
                    });
                } else {
                    return validator;
                }
            })
        })));
        props.changeData(props.data.map(elem => elem.technicalId === props.validator.technicalId ? {
            ...elem,
            email: value
        } : elem));
    };

    const roleChange = useCallback((option: IValue) => {
        if (option?.data) {
            props.changeData(props.data.map(elem => elem.technicalId === props.validator.technicalId ? {
                ...elem,
                labelId: option.data?.id,
                role: {
                    key: option.data.Key,
                    id: option.data.id,
                    name: option.data?.name
                }
            } : elem));
        }
    }, [props.validatorIndex, props.stageIndex, formikContext, props.data, props.changeData]);

    const actionChange = useCallback((option: IValue) => {
        if (option?.data) {
            props.changeData(props.data.map(elem => elem.technicalId === props.validator.technicalId ? {
                ...elem,
                selectedStatusKey: option.data.type,
                validatorType: {
                    id: option.data.type,
                    key: option.data.type,
                    name: option.data?.translationKey
                }
            } : elem));
            formikContext.setFieldValue('stages', formikContext.values.stages.map((stage, stageIndex) => ({
                ...stage, validators: stage.validators.map((validator, validatorIndex) => {
                    if (validator.labelId === props.validator.technicalId) {
                        return ({
                            ...validator, validatorType: option.data.type
                        });
                    } else {
                        return validator;
                    }
                })
            })));
        }
    }, [props.validatorIndex, props.stageIndex, formikContext, props.data, props.changeData]);

    const peopleChange = useCallback((user: { key: string, text: string, data: IUserBrief }[]) => {
        setFullNameText(user?.length > 0 ? user : undefined);
        setEmailText(user?.length > 0 ? user?.[0]?.data?.emailContact : '');
        if (user.length === 0) {
            props.changeData(props.data.map(elem => elem.technicalId === props.validator.technicalId ? {
                ...elem,
                // tslint:disable-next-line:no-null-keyword
                childId: null,
                childName: '',
                picture: '',
                email: ''
            } : elem));
        } else {
            props.changeData(props.data.map(elem => elem.technicalId === props.validator.technicalId ? {
                ...elem,
                childId: user?.[0].data.id,
                childName: user?.[0]?.data?.firstName ? `${user?.[0]?.data?.firstName} ${user?.[0]?.data?.lastName}` : '',
                picture: user?.[0]?.data?.picture || '',
                email: user?.[0]?.data?.emailContact || ''
            } : elem));
        }

        formikContext.setFieldValue('stages', formikContext.values.stages.map((stage, stageIndex) => ({
            ...stage, validators: stage.validators.map((validator, validatorIndex) => {
                if (validator.labelId === props.validator.technicalId) {
                    return ({
                        ...validator, principal: {
                            ...validator.principal, childId: validatorIndex, childName: user?.[0] ? `${user?.[0]?.data?.firstName} ${user?.[0]?.data?.lastName}` : undefined,
                            picture: user?.[0]?.data?.picture, email: user?.[0]?.data?.emailContact
                        }
                    });
                } else {
                    return validator;
                }
            })
        })));
    }, [props.validatorIndex, props.stageIndex, formikContext, props.changeData, props.data]);

    const delApprover = useCallback(() => {
        props.changeData(props.data.filter((elem, index) => index !== props.validatorIndex));
        formikContext.setFieldValue('stages', formikContext.values.stages.filter((elem, index) => index !== props.validatorIndex));
    }, [props.validatorIndex, props.stageIndex, formikContext, props.changeData, props.data]);

    return (
        <Wrapper>
            <DelRecipient onClick={delApprover} width={24} height={24} />
            <Row>
                <div>
                    <NewDesignDropdown
                        inputLabel={intlFormatMessage({ id: 'approval.label.role' })}
                        type={EDropDownType.SEARCH}
                        value={props.validator?.role?.id ? [{ key: props.validator?.role?.id, text: props.validator?.role?.name, data: props.validator?.role }] : undefined}
                        onChange={roleChange}
                        options={roles?.map(elem => ({
                            key: elem.id,
                            text: elem.name,
                            data: elem
                        }))}
                    />
                </div>
                <div>
                    <NewDesignDropdown
                        inputLabel={intlFormatMessage({ id: 'approval.label.validatorTypes' })}
                        required
                        type={EDropDownType.SEARCH}
                        value={props.validator?.selectedStatusKey ? [{ key: props.validator?.validatorType.id, text: intlFormatMessage({ id: props.validator?.validatorType.name }), data: props.validator?.validatorType }] : undefined}
                        onChange={actionChange}
                        options={statuses?.map(elem => ({
                            key: elem.type,
                            text: intlFormatMessage({ id: elem.translationKey }),
                            data: elem
                        }))}
                    />
                </div>
            </Row>
            <Row>
                <div>
                    <UsersPicker
                        value={fullNameText}
                        label={intlFormatMessage({ id: 'approval.label.fullName' })}
                        onSelectElement={peopleChange}
                    />
                </div>
                <div>
                    <TextComponent
                        label={intlFormatMessage({ id: 'approval.label.email' })}
                        required
                        value={emailText}
                        onChange={handleChangeEmail}
                    />
                </div>
            </Row>
        </Wrapper>
    );
};
