import React, { FC, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { EPOAContext, IPOAContext } from '.';
import { Row } from '../../../Common/Row/Row';
import { LegalEntitiesPicker } from '../../../Common/Pickers/LegalEntitiesPicker';
import { TextComponent } from '../../../Common/Inputs/TextComponent';
import { UsersPicker } from '../../../Common/Pickers/UsersPicker';
import { IUserBrief } from '../../../../entities/IGlobal';
import { Overview } from '../../../Common/ColoredDatagrid/CellTypeComponents/Overview';
import { useIntlMessage } from '../../../Common/IntlMessage';

const Wrapper = styled.div`
    width: 100%;
    display: block;
    margin: 0 auto;
`;

const InnerWrapper = styled.div`
    width: 100%;
    > div {
        margin-bottom: 1rem
    }
`;

export const Step2: FC = () => {
    const { intlFormatMessage } = useIntlMessage();
    const POAContext = useContext<IPOAContext>(EPOAContext);

    useEffect(() => {
        POAContext.modifyData(undefined, undefined, 2);
    }, []);

    const onChangeDelegator = (element: { key: string; text: string; data: IUserBrief; }[]) => {
        POAContext.modifyData(element?.[0]?.data, 'delegator', 2);
    };

    const onChangeDelegate = (element: { key: string; text: string; data: IUserBrief; }[]) => {
        POAContext.modifyData(element?.[0]?.data, 'delegate', 2);
    };

    const onChangeCompany = (element: { key: string; text: string; data: IUserBrief; }[]) => {
        POAContext.modifyData(element?.[0]?.data, 'company', 2);
    };

    const onChangeDelegatorCapacity = (value: string) => {
        POAContext.modifyData(value, 'delegatorCapacity', 2);
    };

    const onChangeDelegateCapacity = (value: string) => {
        POAContext.modifyData(value, 'delegateCapacity', 2);
    };

    const onChangeClusterName = (value: string) => {
        POAContext.modifyData(value, 'declarationName', 2);
    };

    return (
        <Wrapper>
            <InnerWrapper>
                {/* <Overview /> Waiting for S161 to get data and uncomment this */}
                <Row numberOfElements={2} noBorder>
                    <div>
                        <LegalEntitiesPicker
                            label={intlFormatMessage({ id: 'poa.wizard.label.company' })}
                            allCompanies
                            required
                            value={POAContext.data?.company?.id ? [{
                                key: POAContext.data?.company?.id,
                                text: POAContext.data?.company.Name,
                                data: POAContext.data?.company
                            }] : undefined}
                            onSelectElement={onChangeCompany}
                        />
                    </div>
                    <div>
                    </div>
                </Row>
                <Row numberOfElements={2} noBorder>
                    <div>
                        <UsersPicker
                            label={intlFormatMessage({ id: 'poa.wizard.label.delegator' })}
                            required
                            value={POAContext.data?.delegator?.id ? [{
                                key: POAContext.data?.delegator?.id,
                                text: `${POAContext.data?.delegator?.firstName}
                                    ${POAContext.data?.delegator?.lastName}`,
                                data: POAContext.data?.delegator
                            }] : undefined}
                            onSelectElement={onChangeDelegator}
                        />
                    </div>
                    <div>
                        <TextComponent
                            label={intlFormatMessage({ id: 'poa.wizard.label.delegatorCapacityOf' })}
                            required
                            value={POAContext.data?.delegatorCapacity}
                            onChange={onChangeDelegatorCapacity}
                        />
                    </div>
                </Row>
                <Row numberOfElements={2} noBorder>
                    <div>
                        <UsersPicker
                            label={intlFormatMessage({ id: 'poa.wizard.label.delegate' })}
                            required
                            value={POAContext.data?.delegate?.id ? [{
                                key: POAContext.data?.delegate?.id,
                                text: `${POAContext.data?.delegate?.firstName}
                                    ${POAContext.data?.delegate?.lastName}`,
                                data: POAContext.data?.delegate
                            }] : undefined}
                            onSelectElement={onChangeDelegate}
                        />
                    </div>
                    <div>
                        <TextComponent
                            value={POAContext.data?.delegateCapacity}
                            onChange={onChangeDelegateCapacity}
                            label={intlFormatMessage({ id: 'poa.wizard.label.delegateCapacityOf' })}
                            required
                        />
                    </div>
                </Row>
                <Row numberOfElements={1} noBorder>
                    <TextComponent
                        label={intlFormatMessage({ id: 'poa.wizard.label.declarationName' })}
                        required
                        value={POAContext.data?.declarationName}
                        onChange={onChangeClusterName}
                    />
                </Row>
            </InnerWrapper>
        </Wrapper>
    );
};