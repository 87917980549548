import React, { FC } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { PrimitiveType, FormatXMLElementFn, Options as IntlMessageFormatOptions } from 'intl-messageformat';

import { ILanguageReducer } from '../../reducers/languageReducer';
import { IState } from '../../reducers';

interface IProps {
    id: string;
    values?: Record<string, any>;
    tagName?: React.ElementType<any>;
}

const IntlMessage: FC<IProps> = props => {
    const { translations, userLanguage } = useSelector<IState, ILanguageReducer>(state => state.language);
    return (
        <FormattedMessage id={translations?.[userLanguage]?.[props.id] ? props.id : 'global.defaultMessage'} values={props.values} tagName={props.tagName} />
    );
};

export default IntlMessage;

export const useIntlMessage = () => {
    const intl = useIntl();
    const { translations, userLanguage } = useSelector<IState, ILanguageReducer>(state => state.language);

    const intlFormatMessage = (data: MessageDescriptor, values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>) => {
        return intl.formatMessage({ id: translations?.[userLanguage]?.[data.id] ? data.id : 'global.defaultMessage' }, values || undefined);
    };

    return { intlFormatMessage };
};