import { RefObject } from 'react';

import { colorStack } from '../styleHelpers/colors';
import { EFacetsTypes } from './IFilters';
import { ELanguageType } from './ILanguage';
import { IValue } from './IPickers';

export interface IEnumObject {
    enumElement: string;
    translationKey: string;
    value: string;
}

export interface ISingleFile {
    contentType: string;
    fileId: string;
    name: string;
    path: string;
}

export interface IReferencial {
    Key: string;
    key?: string;
    id: string;
    logicalId: number;
    name: string;
    parents?: string[];
    referentialIdentityId?: string;
    facetType?: EFacetsTypes;
}

export interface IViewReferential {
    key: string;
    parentId: string;
    uniqueKey: string;
}

export interface IUserBrief {
    firstName: string;
    lastName: string;
    jobTitle: string;
    picture: string;
    cabinet: string;
    id: string;
    emailContact?: string;
    type?: EProfileType.Personal;
}

export enum EProfileType {
    Personal = 'personal',
    Group = 'group',
    Company = 'company',
    Cluster = 'cluster',
    LegalEntity = 'legalentity'
}

export enum EFilterType {
    default = 'default',
    personal = 'personal',
    status = 'status',
    company = 'company',
    date = 'date'
}

export interface ITeamReferential {
    DepartmentReferentialId: string;
    id: string;
    Name: string;
}

export enum EReferentials {
    Nationality = 'Nationality',
    FreeDeclarationObject = 'FreeDeclarationObject',
    Theme = 'Theme',
    Currency = 'Currency',
    Group = 'Group',
    Position = 'Position',
    SecondaryEstablishmentsAndBranches = 'SecondaryEstablishmentsAndBranches',
    SubsidiaryAndParticipations = 'SubsidiaryAndParticipations',
    TypeOfLegalEntity = 'TypeOfLegalEntity',
    LegalForm = 'LegalForm',
    FirstReferential = 'FirstReferential',
    SecondReferential = 'SecondReferential',
    ThirdReferential = 'ThirdReferential',
    Information = 'Information',
    Administration = 'Administration',
    Thematic = 'Thematic',
    Country = 'Country',
    QualityBranches = 'QualityBranches',
    QualityParticipations = 'QualityParticipations',
    NumberOfEmployees = 'NumberOfEmployees',
    DocumentLabel = 'DocumentLabel',
    Clauses = 'Clauses',
    TypeOfActivity = 'TypeOfActivity',
    AliasType = 'AliasType',
    GiftNature = 'GiftNature',
    GiftRelationshipNature = 'GiftRelationshipNature',
    GiftValue = 'GiftValue',
    GiftDescription = 'GiftDescription',
    TypeOfMilestone = 'TypeOfMilestone',
    ContractTypeReferential = 'ContractTypeReferential',
    Assignment = 'Assignment',
    NonCompeteProvision = 'NonCompeteProvision',
    ChangeOfControl = 'ChangeOfControl',
    GeographicalArea = 'GeographicalArea',
    PerformanceObligation = 'PerformanceObligation',
    TypeOfRenewal = 'TypeOfRenewal',
    BasicPrice = 'BasicPrice',
    Exclusivity = 'Exclusivity',
    ApplicableLaw = 'ApplicableLaw',
    ProductAndServices = 'ProductAndServices',
    Cancellation = 'Cancellation',
    ContractDuration = 'ContractDuration',
    GenericQualifiaction = 'GenericQualifiaction',
    ContractTermType = 'ContractTermType',
    BusinessSector = 'BusinessSector',
    Liability = 'Liability',
    Department = 'Department',
    KeyDateStatus = 'KeyDateStatus',
    KeyDateTitle = 'KeyDateTitle',
    InvitationNature = 'InvitationNature',
    InvitationType = 'InvitationType',
    NatureOfRelationship = 'NatureOfRelationship',
    ApprovalLabel = 'ApprovalLabel',
    CoiConflictStatusType = 'CoiConflictStatusType',
    CoiManagerDecision = 'CoiManagerDecision',
    CoiLinkType = 'CoiLinkType',
    ListOfLobbyingRecords = 'ListOfLobbyingRecords',
    LegalSupportSubject = 'LegalSupportSubject',
    Percentage = 'Percentage',
    TypeOfSponsoringProject = 'TypeOfSponsoringProject',
    OriginOfSponsoringProject = 'OriginOfSponsoringProject',
    SponsoringFileType = 'SponsoringFileType',
    EventNature = 'EventNature',
    EventFileType = 'EventFileType',
    GroupingFileType = 'GroupingFileType',
    FreeDeclarationFileType = 'FreeDeclarationFileType',
    GroupingBankGuarantees = 'GroupingBankGuarantees',
    GroupingCommitment = 'GroupingCommitment'
}

export enum EReferenceType {
    LegalEntity = 'LegalEntity'
}

export enum EContextList {
    Data = 'Data',
    Stake = 'Stake',
    Profile = 'Profile',
    Client = 'Client',
    NormativeDocumentation = 'NormativeDocumentation',
    Quotation = 'Quotation',
    Platform = 'Platform',
    LegalEntity = 'LegalEntity',
    BasicCluster = 'BasicCluster',
    Contract = 'Contract',
    Corporate = 'Corporate',
    POA = 'POA ',
    GiftInvitation = 'GiftInvitation',
    Sponsoring = 'Sponsoring',
    COI = 'COI',
    LegalSupport = 'LegalSupport',
    Lobbing = 'Lobbing',
    AssetManagement = 'AssetManagement',
    EApproval = 'EApproval'
}

export interface Icon {
    width?: number | string;
    height?: number | string;
    [key: string]: any;
    className?: string;
    selected?: boolean;
    innerRef?: RefObject<SVGSVGElement>;
}

export enum EPopupSizes {
    XSMALL = 'XSMALL',
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    BIG = 'BIG',
    MONSTER = 'MONSTER',
    FULL = 'FULL'
}

export interface IAddressGlobal {
    number: string;
    street: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    countryShort: string;
    CountryShort?: string;
}

export interface ISingleAttachment {
    name: string;
    uri: string;
    fileType: string;
    size: string;
}

export interface ISearchResponse<T> {
    items: T[];
    size: number;
    page: number;
    count: number; // Obsolete. Try to use size and itemsOnPageCount instead
    itemsOnPageCount: number;
}

export enum EClusterStatus {
    Active = 'Active',
    Closed = 'Closed',
    Archived = 'Archived'
}

export enum ESignatureStatus {
    Expired = 'Expired',
    InForce = 'InForce',
    WaitingForSignature = 'WaitingForSignature',
    WaitingForApostil = 'WaitingForApostil',
    DraftOnGoing = 'DraftOnGoing',
    ToDo = 'ToDo'
}

export enum EAssesmentStatus {
    AssessmentRequired = 'AssessmentRequired',
    PositiveOpinionWithoutRestriction = 'PositiveOpinionWithoutRestriction',
    PositiveOpinionWithRestrictions = 'PositiveOpinionWithRestrictions',
    NegativeOpinion = 'NegativeOpinion',
    'sponsoring.assessmentrequired' = 'sponsoring.assessmentrequired',
    'sponsoring.positiveopinionwithoutrestriction' = 'sponsoring.positiveopinionwithoutrestriction',
    'sponsoring.positiveopinionwithrestrictions' = 'sponsoring.positiveopinionwithrestrictions',
    'sponsoring.negativeopinion' = 'sponsoring.negativeopinion'
}

export enum ECoiStatus {
    EvaluationRequired = 'EvaluationRequired',
    NoConflictOfInterest = 'NoConflictOfInterest',
    PotentialConflictOfInterest = 'PotentialConflictOfInterest',
    ApparentConflictOfInterest = 'ApparentConflictOfInterest',
    'coi.conflict-status.evaluationrequired' = 'coi.conflict-status.evaluationrequired',
    'coi.conflict-status.noconflictofinterest' = 'coi.conflict-status.noconflictofinterest',
    'coi.conflict-status.potentialconflictofinterest' = 'coi.conflict-status.potentialconflictofinterest',
    'coi.conflict-status.apparentconflictofinterest' = 'coi.conflict-status.apparentconflictofinterest'
}

export enum ECorporateMandateStatus {
    NoStatus = 'corporate.mandate-status.nostatus',
    Active = 'corporate.mandate-status.active',
    Expired = 'corporate.mandate-status.expired',
    EmminentEnd = 'corporate.mandate-status.emminentend',
    Inactive = 'corporate.mandate-status.inactive'
}

export enum ECampaignStatus {
    'campaign.status.closed' = 'campaign.status.closed',
    'campaign.status.active' = 'campaign.status.active',
    'campaign.status.draft' = 'campaign.status.draft',
    'campaign.status.completed' = 'campaign.status.completed'
}

export enum ECampaignRecipientStatus {
    'campaign.recipient.status.pending' = 'campaign.recipient.status.pending',
    'campaign.recipient.status.emailSent' = 'campaign.recipient.status.emailSent',
    'campaign.recipient.status.acceptedInSendgrid' = 'campaign.recipient.status.acceptedInSendgrid',
    'campaign.recipient.status.emailDelivered' = 'campaign.recipient.status.emailDelivered',
    'campaign.recipient.status.emailOpened' = 'campaign.recipient.status.emailOpened',
    'campaign.recipient.status.urlOpened' = 'campaign.recipient.status.urlOpened',
    'campaign.recipient.status.completed' = 'campaign.recipient.status.completed',
    'campaign.recipient.status.errorDelivery' = 'campaign.recipient.status.errorDelivery',
    'campaign.recipient.status.emailGenerationError' = 'campaign.recipient.status.emailGenerationError',
    'campaign.recipient.status.recipientHasOtherActiveCampaign' = 'campaign.recipient.status.recipientHasOtherActiveCampaign',
    'campaign.recipient.status.readyToSend' = 'campaign.recipient.status.readyToSend',
    'campaign.recipient.status.recipientCreated' = 'campaign.recipient.status.recipientCreated',
    'campaign.recipient.status.recipientDuplicatedInCampaign' = 'campaign.recipient.status.recipientDuplicatedInCampaign'
}

export enum EAcceptanceStatus {
    NoResponse = 'NoResponse',
    Draft = 'Draft',
    InternalReview = 'InternalReview',
    AwaitingApproval = 'AwaitingApproval',
    Approved = 'Approved',
    Closed = 'Closed',
    DealBreaker = 'DealBreaker'
}

export enum EValidatorStatus {
    WaitingForPreviousStep = 'WaitingForPreviousStep',
    InProgress = 'InProgress',
    WaitingForAction = 'WaitingForAction',
    Approved = 'Approved',
    Rejected = 'Rejected',
    Favorable = 'Favorable',
    Unfavorable = 'Unfavorable',
    Acknowledged = 'Acknowledged'
}

export enum EValidationRequestStatus {
    Done = 'Done',
    Disabled = 'Disabled',
    Rejected = 'Rejected',
    Completed = 'Completed',
    Active = 'Active'
}

export enum EValidationRequestStageStatus {
    WaitingForPreviousStep = 'WaitingForPreviousStep',
    InProgress = 'InProgress',
    Done = 'Done',
    Approved = 'Approved',
    Rejected = 'Rejected'
}

export enum EStatus {
    Delivered = 'Delivered',
    Ongoing = 'Ongoing',
    sent = 'sent',
    delivered = 'delivered',
    completed = 'completed',
    declined = 'declined',
    NotStarted = 'NotStarted',
    Overdue = 'Overdue',
    Hidden = 'Hidden',
    DealBroken = 'DealBroken',
    InProgressWithBlank = 'in progress',
    Pending = 'Pending',
    EmailSent = 'EmailSent',
    AcceptedInSendgrid = 'AcceptedInSendgrid',
    ErrorDelivery = 'ErrorDelivery',
    EmailDelivered = 'EmailDelivered',
    EmailOpened = 'EmailOpened',
    UrlOpened = 'UrlOpened',
    NoConflict = 'NoConflict',
    NoDeclaration = 'NoDeclaration',
    HasOtherActiveCampaign = 'HasOtherActiveCampaign',
    AtLeastOneDeclarationRequireAssessment = 'AtLeastOneDeclarationRequireAssessment',
    OneToManyPotentialConflictOfInterest = 'OneToManyPotentialConflictOfInterest',
    OneToManyConflictOfInterest = 'OneToManyConflictOfInterest'
}

const enum EKeyDatesStatus {
    NOTSTARTED = 'poa.keyDates.status.NotStarted',
    INPROGRESS = "poa.keyDates.status.InProgress",
    OVERDUE = 'poa.keyDates.status.Overdue',
    DONE = 'poa.keyDates.status.Done'
}

export const DEFAULT_CURRENCY_KEY = 'Platform.Currency.EUR';

export type AllStatusEnums = EStatus | EClusterStatus | EKeyDatesStatus | ESignatureStatus | EAssesmentStatus | ECoiStatus | ECorporateMandateStatus | ECampaignStatus | ECampaignRecipientStatus | EAcceptanceStatus | EValidatorStatus | EValidationRequestStatus | EValidationRequestStageStatus | ELanguageType;

export const StatusMapper: IValue[] = [
    { key: EAcceptanceStatus.NoResponse, text: `status.${EAcceptanceStatus.NoResponse}`, data: { color: colorStack.red, fontColor: colorStack.darkGrey } },
    { key: EKeyDatesStatus.NOTSTARTED, text: `poa.keyDates.status.${EKeyDatesStatus.NOTSTARTED}`, data: { color: colorStack.black, fontColor: colorStack.darkGrey } },
    { key: EKeyDatesStatus.INPROGRESS, text: `poa.keyDates.status.${EKeyDatesStatus.INPROGRESS}`, data: { color: colorStack.blue, fontColor: colorStack.darkGrey } },
    { key: EKeyDatesStatus.OVERDUE, text: `poa.keyDates.status.${EKeyDatesStatus.OVERDUE}`, data: { color: colorStack.red, fontColor: colorStack.darkGrey } },
    { key: EKeyDatesStatus.DONE, text: `poa.keyDates.status.${EKeyDatesStatus.DONE}`, data: { color: colorStack.lightGreen, fontColor: colorStack.darkGrey } },
    { key: EAcceptanceStatus.Draft, text: `status.${EAcceptanceStatus.Draft}`, data: { color: colorStack.blue, fontColor: colorStack.darkGrey } },
    { key: EAcceptanceStatus.InternalReview, text: `status.${EAcceptanceStatus.InternalReview}`, data: { color: colorStack.purple, fontColor: colorStack.darkGrey } },
    { key: EAcceptanceStatus.AwaitingApproval, text: `status.${EAcceptanceStatus.AwaitingApproval}`, data: { color: colorStack.orange, fontColor: colorStack.darkGrey } },
    { key: EAcceptanceStatus.Approved, text: `status.${EAcceptanceStatus.Approved}`, data: { color: colorStack.green, fontColor: colorStack.darkGrey } },
    { key: EAcceptanceStatus.DealBreaker, text: `status.${EAcceptanceStatus.DealBreaker}`, data: { color: colorStack.darkGrey, fontColor: colorStack.white } },
    { key: EAcceptanceStatus.Closed, text: `status.${EAcceptanceStatus.Closed}`, data: { color: colorStack.disabled, fontColor: colorStack.darkGrey } },
    { key: EClusterStatus.Archived, text: `status.${EClusterStatus.Archived}`, data: { color: colorStack.disabled, fontColor: colorStack.darkGrey } },
    { key: EValidationRequestStatus.Done, text: `status.${EValidationRequestStatus.Done}`, data: { color: colorStack.middlePurple, fontColor: colorStack.darkGrey } },
    { key: EValidatorStatus.Acknowledged, text: `status.${EValidatorStatus.Acknowledged}`, data: { color: colorStack.green, fontColor: colorStack.white } },
    { key: EValidatorStatus.Unfavorable, text: `status.${EValidatorStatus.Unfavorable}`, data: { color: colorStack.darkRed, fontColor: colorStack.white } },
    { key: EValidatorStatus.Favorable, text: `status.${EValidatorStatus.Favorable}`, data: { color: colorStack.green, fontColor: colorStack.white } },
    { key: EValidationRequestStatus.Disabled, text: `status.${EValidationRequestStatus.Disabled}`, data: { color: colorStack.ligthGrey, fontColor: colorStack.white } },
    { key: EValidationRequestStatus.Completed, text: `status.${EValidationRequestStatus.Completed}`, data: { color: colorStack.green, fontColor: colorStack.white } },
    { key: EValidationRequestStatus.Active, text: `status.${EValidationRequestStatus.Active}`, data: { color: colorStack.orange, fontColor: colorStack.darkGrey } },
    { key: EValidationRequestStatus.Rejected, text: `status.${EValidationRequestStatus.Rejected}`, data: { color: colorStack.darkRed, fontColor: colorStack.darkGrey } },
    { key: EStatus.Delivered, text: `status.${EStatus.Delivered}`, data: { color: colorStack.darkGrey, fontColor: colorStack.white } },
    { key: EValidationRequestStageStatus.InProgress, text: `status.${EValidationRequestStageStatus.InProgress}`, data: { color: colorStack.orange, fontColor: colorStack.darkGrey } },
    { key: EValidationRequestStageStatus.WaitingForPreviousStep, text: `status.${EValidationRequestStageStatus.WaitingForPreviousStep}`, data: { color: colorStack.darkGrey, fontColor: colorStack.white } },
    { key: EStatus.sent, text: `status.${EStatus.sent}`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: EStatus.delivered, text: `status.${EStatus.delivered}`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: EValidatorStatus.WaitingForAction, text: `status.${EValidatorStatus.WaitingForAction}`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: EStatus.Hidden, text: `status.${EStatus.Hidden}`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: EStatus.DealBroken, text: `status.${EStatus.DealBroken}`, data: { color: colorStack.middleRed, fontColor: colorStack.whiteRed } },
    { key: EStatus.Ongoing, text: `status.${EStatus.Ongoing}`, data: { color: colorStack.middleOrange, fontColor: colorStack.whiteOrange } },
    { key: EStatus.completed, text: `status.${EStatus.completed}`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: EStatus.declined, text: `status.${EStatus.declined}`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: EStatus.Overdue, text: `status.${EStatus.Overdue}`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: ESignatureStatus.DraftOnGoing, text: `status.${ESignatureStatus.DraftOnGoing}`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: ESignatureStatus.WaitingForApostil, text: `status.${ESignatureStatus.WaitingForApostil}`, data: { color: colorStack.purple, fontColor: colorStack.whitePurple } },
    { key: ESignatureStatus.WaitingForSignature, text: `status.${ESignatureStatus.WaitingForSignature}`, data: { color: colorStack.darkOrange, fontColor: colorStack.whiteOrange } },
    { key: ESignatureStatus.InForce, text: `status.${ESignatureStatus.InForce}`, data: { color: colorStack.green, fontColor: colorStack.whiteGreen } },
    { key: ESignatureStatus.ToDo, text: `status.${ESignatureStatus.ToDo}`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: ESignatureStatus.Expired, text: `status.${ESignatureStatus.Expired}`, data: { color: colorStack.disabled, fontColor: colorStack.ligthGrey } },
    { key: ECoiStatus.NoConflictOfInterest, text: `status.${ECoiStatus.NoConflictOfInterest}`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: ECoiStatus.PotentialConflictOfInterest, text: `status.${ECoiStatus.PotentialConflictOfInterest}`, data: { color: colorStack.orange, fontColor: colorStack.whiteOrange } },
    { key: ECoiStatus.ApparentConflictOfInterest, text: `status.${ECoiStatus.ApparentConflictOfInterest}`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: ECoiStatus['coi.conflict-status.apparentconflictofinterest'], text: `status.${ECoiStatus.ApparentConflictOfInterest}`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: EStatus.InProgressWithBlank, text: `status.${EStatus.InProgressWithBlank}`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: EStatus.ErrorDelivery, text: `status.${EStatus.ErrorDelivery}`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: EStatus.Pending, text: `status.${EStatus.Pending}`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: EStatus.EmailSent, text: `status.${EStatus.EmailSent}`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: EStatus.EmailDelivered, text: `status.${EStatus.EmailDelivered}`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: EStatus.EmailOpened, text: `status.${EStatus.EmailOpened}`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: EStatus.HasOtherActiveCampaign, text: `status.${EStatus.HasOtherActiveCampaign}`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: EStatus.UrlOpened, text: `status.${EStatus.UrlOpened}`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: EStatus.NoDeclaration, text: `status.${EStatus.NoDeclaration}`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: EStatus.NoConflict, text: `status.${EStatus.NoConflict}`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: EStatus.AtLeastOneDeclarationRequireAssessment, text: `status.${EStatus.AtLeastOneDeclarationRequireAssessment}`, data: { color: colorStack.orange, fontColor: colorStack.whiteOrange } },
    { key: EStatus.OneToManyPotentialConflictOfInterest, text: `status.${EStatus.OneToManyPotentialConflictOfInterest}`, data: { color: colorStack.darkOrange, fontColor: colorStack.whiteOrange } },
    { key: EStatus.OneToManyConflictOfInterest, text: `status.${EStatus.OneToManyConflictOfInterest}`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: EAssesmentStatus.AssessmentRequired, text: `status.${EAssesmentStatus.AssessmentRequired}`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: EAssesmentStatus.PositiveOpinionWithoutRestriction, text: `status.${EAssesmentStatus.PositiveOpinionWithoutRestriction}`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: EAssesmentStatus.PositiveOpinionWithRestrictions, text: `status.${EAssesmentStatus.PositiveOpinionWithRestrictions}`, data: { color: colorStack.orange, fontColor: colorStack.whiteOrange } },
    { key: EAssesmentStatus.NegativeOpinion, text: `status.${EAssesmentStatus.NegativeOpinion}`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: EAssesmentStatus['sponsoring.assessmentrequired'], text: `sponsoring.assessmentrequired`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: EAssesmentStatus['sponsoring.positiveopinionwithoutrestriction'], text: `sponsoring.positiveopinionwithoutrestriction`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: EAssesmentStatus['sponsoring.positiveopinionwithrestrictions'], text: `sponsoring.positiveopinionwithrestrictions`, data: { color: colorStack.orange, fontColor: colorStack.whiteOrange } },
    { key: EAssesmentStatus['sponsoring.negativeopinion'], text: `sponsoring.negativeopinion`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.pending'], text: `campaign.recipient.status.pending`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.emailSent'], text: `campaign.recipient.status.emailSent`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.acceptedInSendgrid'], text: `campaign.recipient.status.acceptedInSendgrid`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.emailDelivered'], text: `campaign.recipient.status.emailDelivered`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.emailOpened'], text: `campaign.recipient.status.emailOpened`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.recipientCreated'], text: `campaign.recipient.status.recipientCreated`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.urlOpened'], text: `campaign.recipient.status.urlOpened`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.completed'], text: `campaign.recipient.status.completed`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.errorDelivery'], text: `campaign.recipient.status.errorDelivery`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.emailGenerationError'], text: `campaign.recipient.status.emailGenerationError`, data: { color: colorStack.red, fontColor: colorStack.whiteRed } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.recipientHasOtherActiveCampaign'], text: `campaign.recipient.status.recipientHasOtherActiveCampaign`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.readyToSend'], text: `campaign.recipient.status.readyToSend`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: ECampaignRecipientStatus['campaign.recipient.status.recipientDuplicatedInCampaign'], text: `campaign.recipient.status.recipientDuplicatedInCampaign`, data: { color: colorStack.middleBlue, fontColor: colorStack.white } },
    { key: ECampaignStatus['campaign.status.closed'], text: `campaign.status.closed`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: ECampaignStatus['campaign.status.active'], text: `campaign.status.active`, data: { color: colorStack.middleBlue, fontColor: colorStack.whiteBlue } },
    { key: ECampaignStatus['campaign.status.draft'], text: `campaign.status.draft`, data: { color: colorStack.disabled, fontColor: colorStack.ligthGrey } },
    { key: ECampaignStatus['campaign.status.completed'], text: `campaign.status.completed`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: ECoiStatus['coi.conflict-status.evaluationrequired'], text: `coi.conflict-status.evaluationrequired`, data: { color: colorStack.black, fontColor: colorStack.white } },
    { key: ECoiStatus['coi.conflict-status.noconflictofinterest'], text: `coi.conflict-status.noconflictofinterest`, data: { color: colorStack.lightGreen, fontColor: colorStack.whiteGreen } },
    { key: ECoiStatus['coi.conflict-status.potentialconflictofinterest'], text: `coi.conflict-status.potentialconflictofinterest`, data: { color: colorStack.orange, fontColor: colorStack.whiteOrange } },
    { key: ELanguageType.Global, text: `orgsSettings.labels.type.global`, data: { color: colorStack.black, fontColor: colorStack.black } },
    { key: ELanguageType.Local, text: `orgsSettings.labels.type.local`, data: { color: colorStack.middleOrange, fontColor: colorStack.whiteOrange } },
    { key: ECorporateMandateStatus.NoStatus, text: `corporate.mandate-status.nostatus`, data: { color: colorStack.middleGrey, fontColor: colorStack.darkGrey } },
    { key: ECorporateMandateStatus.Active, text: `corporate.mandate-status.active`, data: { color: colorStack.green, fontColor: colorStack.darkGrey } },
    { key: ECorporateMandateStatus.EmminentEnd, text: `corporate.mandate-status.emminentend`, data: { color: colorStack.orange, fontColor: colorStack.darkGrey } },
    { key: ECorporateMandateStatus.Expired, text: `corporate.mandate-status.expired`, data: { color: colorStack.red, fontColor: colorStack.darkGrey } },
    { key: ECorporateMandateStatus.Inactive, text: `corporate.mandate-status.inactive`, data: { color: colorStack.disabled, fontColor: colorStack.darkGrey } }
];

export const ValidationStatusMapper: IValue[] = [
    { key: EValidatorStatus.Approved, text: `status.${EValidatorStatus.Approved}`, data: { color: colorStack.whiteGreen, fontColor: colorStack.white } },
    { key: EValidatorStatus.Rejected, text: `status.${EValidatorStatus.Rejected}`, data: { color: colorStack.whiteRed, fontColor: colorStack.white } },
    { key: EValidatorStatus.Acknowledged, text: `status.${EValidatorStatus.Acknowledged}`, data: { color: colorStack.whiteGreen, fontColor: colorStack.white } },
    { key: EValidatorStatus.Unfavorable, text: `status.${EValidatorStatus.Unfavorable}`, data: { color: colorStack.whiteRed, fontColor: colorStack.white } },
    { key: EValidatorStatus.Favorable, text: `status.${EValidatorStatus.Favorable}`, data: { color: colorStack.whiteGreen, fontColor: colorStack.white } },
    { key: EValidatorStatus.WaitingForPreviousStep, text: `status.${EValidatorStatus.WaitingForPreviousStep}`, data: { color: colorStack.ligthGrey, fontColor: colorStack.white } }
];