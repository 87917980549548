import React, { useCallback, useState, useEffect, FC } from 'react';
import { useFormikContext, FormikErrors } from 'formik';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleTrash } from '@fortawesome/pro-solid-svg-icons';

import { getProfileById } from '../../../../actions/profileActions';
import { getReferentials } from '../../../../actions/globalActions';
import { getCompanyById } from '../../../../actions/legalEntitiesActions';
import { EReferentials, EContextList, IUserBrief, EProfileType } from '../../../../entities/IGlobal';
import { IFormData, IBeneficiary } from '../../../../entities/IDeclaration';
import { RadioButton } from '../../../Common/Inputs/RadioButton';
import { TextComponent } from '../../../Common/Inputs/TextComponent';
import { IReferencial } from '../../../../entities/IGlobal';
import { ISIngleLegalEntity } from '../../../../entities/ILegalEntities';
import { UsersPicker } from '../../../Common/Pickers/UsersPicker';
import { LegalEntitiesPicker } from '../../../Common/Pickers/LegalEntitiesPicker';
import { LabelWithEllipsis } from '../../../Common/Label/Label';
import { SelectInput } from '../../../Common/SelectInput/SelectInput';
import { ToolTipWrapper } from '../../../Common/ToolTip/ToolTipWrapper';
import { IValue } from '../../../../entities/IPickers';
import { colorStack } from '../../../../styleHelpers/colors';
import { EDropDownType } from '../../../Common/DropDown/DropDown';
import IntlMessage, { useIntlMessage } from '../../../Common/IntlMessage';

const Wrapper = styled.div`
    position: relative;
    background: ${colorStack.whiteGrey};
    border-radius: 4px;
    margin-bottom: 26px;
    display: flex;
    padding: 24px 16px 16px 16px;
    border-radius: 8px;
    gap: 1rem;
    flex-direction: column;
    > div {
        justify-content: space-between;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const InputSection = styled.div<{ row?: boolean; text?: boolean; radio?: boolean }>`
    width: 49%;
    ${props => props.row && css`
        display: flex;
        flex-direction: row;
    `}
    ${props => props.text && css`
        display: inline-flex;
        align-items: center;
    `}
    ${props => props.radio && css`
        label {
            margin-right: 29px;
        }
    `}
`;

const SensitivePersonBox = styled.div`
    display: flex;
    align-items: center;
    span {
        font-weight: 500;
    }
`;

const DelBeneficiary = styled(FontAwesomeIcon)`
    position: absolute;
    right: 8px;
    top: 4px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    color: ${colorStack.middleRed};
`;

interface IProps {
    errors: FormikErrors<IBeneficiary>;
    index: number;
}

export const SingleBeneficiaryBox: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const { intlFormatMessage } = useIntlMessage();
    const [loading, setLoading] = useState<boolean>(false);
    const { setFieldValue, values } = useFormikContext<IFormData>();
    const [entityValue, setEntityValue] = useState<IValue[]>(undefined);
    const [giftInvitationRelationshipOptions, setGiftInvitationRelationshipOptions] = useState<IReferencial[]>(undefined);
    const [inputBlur, setInputBlur] = useState({
        fullName: false,
        jobTitle: false,
        legalEntity: false,
        email: false,
        relationshipNature: false
    });

    useEffect(() => {
        dispatch(getReferentials('', EReferentials.GiftRelationshipNature, EContextList.GiftInvitation)).then(response => {
            setGiftInvitationRelationshipOptions(response);
        });
    }, []);

    useEffect(() => {
        setEntityValue(values.formData.thirdParties?.[props.index]?.legalEntity?.id ? [{
            key: values.formData.thirdParties?.[props.index]?.legalEntity?.id,
            text: values.formData.thirdParties?.[props.index]?.legalEntity?.name,
            data: values.formData.thirdParties?.[props.index]?.legalEntity
        }] : undefined);
    }, [values.formData.thirdParties, props.index]);

    const handleBlur = useCallback((name: string) => {
        setInputBlur({
            ...inputBlur,
            [name]: true
        });
    }, [inputBlur]);

    const fillUserInformation = useCallback(async (user: IUserBrief) => {
        if (user) {
            const result = await dispatch(getProfileById(user.id));
            setEntityValue(undefined);
            const entityInfo = result.cabinet?.id ? await dispatch(getCompanyById(result.cabinet?.id)) : undefined;
            setFieldValue(`formData.thirdParties`, values.formData.thirdParties.map((beneficiary, index) => index === props.index
                ? ({
                    ...beneficiary,
                    userData: user,
                    fullName: `${result.firstName} ${result.lastName}`,
                    jobTitle: result.jobTitle,
                    email: result.emailContact,
                    userId: result.id,
                    legalEntity: entityInfo ? { ...entityInfo, type: EProfileType.Company } : undefined
                })
                : beneficiary
            ));
        } else {
            setFieldValue(`formData.thirdParties`, values.formData.thirdParties.map((beneficiary, index) => index === props.index
                ? ({
                    ...beneficiary,
                    // tslint:disable-next-line:no-null-keyword
                    userData: null,
                    fullName: '',
                    jobTitle: '',
                    email: '',
                    userId: '',
                    // tslint:disable-next-line:no-null-keyword
                    legalEntity: null
                })
                : beneficiary
            ));
        }
    }, [values.formData, props.index]);

    const fillLegalEntityInformation = (data: ISIngleLegalEntity) => {
        setFieldValue(`formData.thirdParties`, values.formData.thirdParties.map(
            (beneficiary, index) => index === props.index
                ? ({
                    ...beneficiary,
                    // tslint:disable-next-line:no-null-keyword
                    legalEntity: data || null
                })
                : beneficiary
        ));
    };

    const onChangeSelected = useCallback(async (element: { key: string; text: string; data: IUserBrief; }[]) => {
        setFieldValue(`formData.thirdParties`, values.formData.thirdParties.map(
            (beneficiary, index) => index === props.index ?
                ({
                    ...beneficiary, fullName: element?.[0]?.data ? `${element?.[0]?.data?.firstName} ${element?.[0]?.data?.lastName}`
                        // tslint:disable-next-line:no-null-keyword
                        : '', userData: element?.[0]?.data || null
                })
                : beneficiary
        ));
        fillUserInformation(element?.[0]?.data);
    }, [props.index, values.formData.thirdParties]);

    const onChangeSelectedEntity = (element: { key: string; text: string; data: ISIngleLegalEntity; }[]) => {
        setEntityValue(element);
        fillLegalEntityInformation(element?.[0]?.data);
    };

    const handleChange = useCallback((fieldName: string, value: string | boolean | React.ChangeEvent<any>) => {
        setFieldValue(
            `formData.thirdParties`,
            values.formData.thirdParties.map((beneficiary, index) => index === props.index
                ? ({ ...beneficiary, [fieldName]: value })
                : beneficiary
            )
        );
    }, [values.formData.thirdParties, props.index]);

    const deleteBeneficary = () => {
        setLoading(true);
        setFieldValue('formData.thirdParties', values.formData.thirdParties.filter((elem, index) => index !== props.index));
        setTimeout(() => {
            setLoading(false);
        }, 50);
    };

    return (
        <>
            {!loading &&
                <Wrapper>
                    <DelBeneficiary icon={faCircleTrash} onClick={deleteBeneficary} />
                    <InputSection className="lc-st-gift-invitation-wizard-step4-fullName">
                        <LabelWithEllipsis required>
                            <IntlMessage id="giftinvitation.beneficiary.fullName" />
                        </LabelWithEllipsis>
                        <UsersPicker
                            value={values.formData.thirdParties[props.index]?.userData?.id ? [{
                                key: values.formData.thirdParties[props.index]?.userData.id,
                                text: `${values.formData.thirdParties[props.index]?.userData?.firstName} ${values.formData.thirdParties[props.index]?.userData?.lastName}`,
                                data: values.formData.thirdParties[props.index]?.userData
                            }] : []}
                            onSelectElement={onChangeSelected}
                            filter={values.formData?.thirdParties?.map(elem => elem?.userId).filter(elem => elem)}
                        />
                    </InputSection>
                    <Row>
                        <InputSection className="lc-st-gift-invitation-wizard-step4-jobTitle">
                            <LabelWithEllipsis>
                                <IntlMessage id="giftinvitation.beneficiary.jobTitle" />
                            </LabelWithEllipsis>
                            <TextComponent
                                value={values.formData.thirdParties?.[props.index].jobTitle}
                                error={inputBlur.jobTitle && !!props.errors?.jobTitle}
                                onChange={(value: string) => handleChange('jobTitle', value)}
                                onBlur={() => handleBlur('jobTitle')}
                            />
                        </InputSection>
                        <InputSection className="lc-st-gift-invitation-wizard-step4-legalEntity">
                            <LabelWithEllipsis required>
                                <IntlMessage id="giftinvitation.beneficiary.legalEntity" />
                            </LabelWithEllipsis>
                            <LegalEntitiesPicker
                                value={entityValue}
                                onSelectElement={onChangeSelectedEntity}
                                allCompanies
                            />
                        </InputSection>
                    </Row>
                    <Row>
                        <InputSection className="lc-st-gift-invitation-wizard-step4-email">
                            <LabelWithEllipsis required>
                                <IntlMessage id="giftinvitation.beneficiary.email" />
                            </LabelWithEllipsis>
                            <TextComponent
                                value={values.formData.thirdParties?.[props.index]?.email}
                                error={inputBlur.email && !!props.errors?.email}
                                onChange={(value: string) => handleChange('email', value)}
                                onBlur={() => handleBlur('email')}
                            />
                        </InputSection>
                        <InputSection className="lc-st-gift-invitation-wizard-step4-relationshipNature">
                            <LabelWithEllipsis required>
                                <IntlMessage id="giftinvitation.beneficiary.relationshipNature" />
                            </LabelWithEllipsis>
                            <SelectInput
                                value={values.formData.thirdParties?.[props.index]?.relationshipNature ? [{key: values.formData.thirdParties?.[props.index]?.relationshipNature?.id, text: values.formData.thirdParties?.[props.index]?.relationshipNature?.name, data: values.formData.thirdParties?.[props.index]?.relationshipNature}] : undefined}
                                onChange={(val) => handleChange('relationshipNature', val?.data)}
                                options={giftInvitationRelationshipOptions?.map(elem => ({
                                    key: elem.id,
                                    text: elem.name,
                                    data: elem
                                }))}
                                type={EDropDownType.DEFAULT}
                            />
                        </InputSection>
                    </Row>
                    <Row>
                        <InputSection text className="lc-st-gift-invitation-wizard-step4-sensitivePerson">
                            <SensitivePersonBox>
                                <IntlMessage id="giftinvitation.beneficiary.sensitivePerson" tagName="span" />
                                <ToolTipWrapper withIco fixedWidth={'900'} title={intlFormatMessage({ id: 'giftinvitation.beneficiary.tooltip' })} />
                            </SensitivePersonBox>
                        </InputSection>
                        <InputSection row radio>
                            <RadioButton
                                label={intlFormatMessage({ id: 'global.yes' })}
                                checked={values.formData.thirdParties?.[props.index]?.sensitivePerson === true}
                                onClick={() => handleChange('sensitivePerson', true)}
                            />
                            <RadioButton
                                label={intlFormatMessage({ id: 'global.no' })}
                                checked={values.formData.thirdParties?.[props.index]?.sensitivePerson === false}
                                onClick={() => handleChange('sensitivePerson', false)}
                            />
                        </InputSection>
                    </Row>
                </Wrapper>
            }
        </>
    );
};
