import React, { FC, useContext, useEffect } from 'react';

import { EPOAContext, IPOAContext } from '.';
import { useIntlMessage } from '../../../Common/IntlMessage';

export const Step3: FC = () => {
    const { intlFormatMessage } = useIntlMessage();
    const POAContext = useContext<IPOAContext>(EPOAContext);

    useEffect(() => {
        POAContext.modifyData(undefined, undefined, 1);
    }, []);

    return (
        <div>
            <div dangerouslySetInnerHTML={{__html: intlFormatMessage({id: 'poa.wizard.step3.content'})}} />
        </div>
    );
};