import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';

import { EApprovalContext, EApprovalTypes, IEApprovalContext, IMainStepProps } from '..';
import { BadgeWrapper, GreyBox, PrinciplesContainer } from '../common';
import { Row } from '../../../../Common/Row/Row';
import { SelectInput } from '../../../../Common/SelectInput/SelectInput';
import { EDropDownType } from '../../../../Common/DropDown/DropDown';
import { IValue } from '../../../../../entities/IPickers';
import { UsersPicker } from '../../../../Common/Pickers/UsersPicker';
import { IUserBrief } from '../../../../../entities/IGlobal';
import { getTypesWithStatuses } from '../../../../../actions/validationActions';
import { ITypesWithStatuses } from '../../../../../entities/IValidation';
import { Button, EButtonTypeSchema } from '../../../../Common/Buttons/NewButton';
import { Badge, EBadgeColor, EBadgeShape, EBadgeSize } from '../../../../Common/Badge/Badge';
import IntlMessage, { useIntlMessage } from '../../../../Common/IntlMessage';

type GetTypesWithStatuses = ReturnType<typeof getTypesWithStatuses>;

const Wrapper = styled.div`
    width: 100%;
`;

const CeoCfoContainer = styled.div`
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-bottom: 1.5rem;
`;

const InfoContainer = styled.div`
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const GroupingStep7: FC<IMainStepProps> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const dispatch = useDispatch();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);
    const [statuses, setStatuses] = useState<ITypesWithStatuses[]>(undefined);

    useEffect(() => {
        if (!eApprovalContext.data?.[EApprovalTypes.Grouping]?.ceo) {
            eApprovalContext.modifyData({ ...eApprovalContext.data?.[EApprovalTypes.Grouping]?.cfo, stageId: 2, status: {name: 'Approval', type: 'Approval'} }, '', props.context, 'cfo', props.stepNumber);
            eApprovalContext.modifyData({ ...eApprovalContext.data?.[EApprovalTypes.Grouping]?.ceo, stageId: 3, status: {name: 'Approval', type: 'Approval'} }, '', props.context, 'ceo', props.stepNumber);
        }
    }, []);

    useEffect(() => {
        dispatch<GetTypesWithStatuses>(getTypesWithStatuses()).then(response => {
            setStatuses(response);
        });
    }, []);

    const onChangeStatus = (option: IValue, index: number) => {
        eApprovalContext.modifyData({type: option?.data.type, name: option?.text}, 'status', props.context, props.stepName, props.stepNumber, index);
    };

    const onChangeManager = (element: { key: string; text: string; data: IUserBrief; }[], index: number) => {
        eApprovalContext.modifyData(element?.[0]?.data, 'approver', props.context, props.stepName, props.stepNumber, index);
    };

    const onChangeCfoCeo = (element: { key: string; text: string; data: IUserBrief; }[], propertyName: string) => {
        eApprovalContext.modifyData(element?.[0]?.data, 'approver', props.context, propertyName, props.stepNumber);
    };

    const onAddItem = () => {
        eApprovalContext.modifyData({}, 'approver', props.context, props.stepName, props.stepNumber, eApprovalContext.data?.[EApprovalTypes.Grouping]?.approvers?.length || 0);
        eApprovalContext.modifyData(1, 'stageId', props.context, props.stepName, props.stepNumber, eApprovalContext.data?.[EApprovalTypes.Grouping]?.approvers?.length || 0);
    };

    const removeElement = (index: number) => {
        eApprovalContext.removeFromArrays(props.context, props.stepName, index);
    };

    const addItemValid = () => {
        if (eApprovalContext.data?.[EApprovalTypes.Grouping]?.approvers?.length > 0) {
            return !(
                (eApprovalContext.data?.[EApprovalTypes.Grouping]?.approvers || [])?.map(elem => (!!elem?.approver?.firstName && !!elem.status))?.includes(false) === false &&
                (eApprovalContext.data?.[EApprovalTypes.Grouping]?.cfo?.approver?.id &&
                eApprovalContext.data?.[EApprovalTypes.Grouping]?.ceo?.approver?.id)
              );
        }
        return !(eApprovalContext.data?.[EApprovalTypes.Grouping]?.cfo?.approver?.id && eApprovalContext.data?.[EApprovalTypes.Grouping]?.ceo?.approver?.id);
    };

    return (
        <Wrapper>
            <PrinciplesContainer>
                <div dangerouslySetInnerHTML={{
                    __html: intlFormatMessage({ id: `eApproval.wizard.step5.approvers.grouping` })
                }} />
            </PrinciplesContainer>
            <CeoCfoContainer>
                <Row numberOfElements={2} noBorder>
                    <UsersPicker
                        value={eApprovalContext.data?.[EApprovalTypes.Grouping]?.cfo?.approver?.id ? [{
                            key: eApprovalContext.data?.[EApprovalTypes.Grouping]?.cfo?.approver?.id,
                            text: `${eApprovalContext.data?.[EApprovalTypes.Grouping]?.cfo?.approver?.firstName}
                                ${eApprovalContext.data?.[EApprovalTypes.Grouping]?.cfo?.approver?.lastName}`,
                            data: eApprovalContext.data?.[EApprovalTypes.Grouping]?.cfo?.approver
                        }] : undefined}
                        onSelectElement={value => onChangeCfoCeo(value, 'cfo')}
                        required
                        label={<IntlMessage id={`eApproval.wizard.approvers.approver.cfo`} />}
                    />
                    <UsersPicker
                        value={eApprovalContext.data?.[EApprovalTypes.Grouping]?.ceo?.approver?.id ? [{
                            key: eApprovalContext.data?.[EApprovalTypes.Grouping]?.ceo?.approver?.id,
                            text: `${eApprovalContext.data?.[EApprovalTypes.Grouping]?.ceo?.approver?.firstName}
                                ${eApprovalContext.data?.[EApprovalTypes.Grouping]?.ceo?.approver?.lastName}`,
                            data: eApprovalContext.data?.[EApprovalTypes.Grouping]?.ceo?.approver
                        }] : undefined}
                        onSelectElement={value => onChangeCfoCeo(value, 'ceo')}
                        required
                        label={<IntlMessage id={`eApproval.wizard.approvers.approver.ceo`} />}
                    />
                </Row>
            </CeoCfoContainer>
            <InfoContainer>
                <div dangerouslySetInnerHTML={{
                    __html: intlFormatMessage({ id: `eApproval.wizard.step5.approvers.grouping.info2` })
                }} />
            </InfoContainer>
            {eApprovalContext.data?.[EApprovalTypes.Grouping]?.approvers?.map((elem, index) =>
                <GreyBox key={index}>
                    <BadgeWrapper>
                        <Badge icon={faTrash} shape={EBadgeShape.CIRCLE} color={EBadgeColor.RED} size={EBadgeSize.SMALL} onClick={() => removeElement(index)} />
                    </BadgeWrapper>
                    <Row numberOfElements={2} noBorder>
                        <UsersPicker
                            value={elem.approver?.id ? [{
                                key: elem.approver?.id,
                                text: `${elem.approver?.firstName}
                                    ${elem.approver?.lastName}`,
                                data: elem.approver
                            }] : undefined}
                            onSelectElement={value => onChangeManager(value, index)}
                            required
                            label={<IntlMessage id={`eApproval.wizard.approvers.approver`} />}
                        />
                        <SelectInput
                            value={elem.status?.type ? [{
                                key: elem.status?.type,
                                text: elem.status?.name,
                                data: elem.status
                            }] : undefined}
                            required
                            onChange={(option) => onChangeStatus(option, index)}
                            inputLabel={<IntlMessage id={'eApproval.wizard.approvers.status'} />}
                            options={statuses?.map(status => ({
                                key: status.type,
                                text: status.translationKey ? intlFormatMessage({ id: status.translationKey }) : '',
                                data: status
                            }))}
                            type={EDropDownType.DEFAULT}
                        />
                    </Row>
                </GreyBox>
            )}
            <Button type="button"
                typeSchema={EButtonTypeSchema.TEXT}
                disabled={addItemValid()}
                onClick={onAddItem}
                leftIco={faPlus}
                >
                <IntlMessage id="eApproval.button.add-approver" />
            </Button>
        </Wrapper>
    );
};