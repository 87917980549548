import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useDispatch } from 'react-redux';

import { colorStack } from '../../../../styleHelpers/colors';
import { cardsShadow } from '../../../../styleHelpers/mixins/shadow';
import { Delete } from '../../Icons';
import { fontSize, fontSizeAndHeight } from '../../../../styleHelpers/fontSizes';
import { TextComponent } from '../../Inputs/TextComponent';
import { Button } from '../../Buttons/NewButton';
import { GoogleAddressEditor, IAddressObject } from '../../GoogleAddress/GoogleAddressEditor';
import { SelectInput } from '../../SelectInput/SelectInput';
import { IAddressGlobal, EContextList, EProfileType, IReferencial, EReferentials } from '../../../../entities/IGlobal';
import { getReferentials } from '../../../../actions/globalActions';
import { newUpdateCompany } from '../../../../actions/legalEntitiesActions';
import { EAlertType } from '../../../../entities/IAlert';
import { useAlert } from '../../../../tools/hooks';
import { IValue } from '../../../../entities/IPickers';
import { LabelWithEllipsis } from '../../Label/Label';
import { EDropDownType } from '../../DropDown/DropDown';
import IntlMessage from '../../IntlMessage';

type GetReferentials = ReturnType<typeof getReferentials>;
type NewUpdateCompany = ReturnType<typeof newUpdateCompany>;

const Wrapper = styled.div<{outsideScreen: number, width: number}>`
    position: absolute;
    border-radius: 8px;
    background: ${colorStack.white};
    ${cardsShadow()};
    z-index: 9999;
    ${props => props.outsideScreen > 0 && css`
        bottom: 40px;
    `}
    ${props => props.width && css`
        width: ${`${props.width}px`};
    `}
`;

const CloseForm = styled(Delete)`
    position: absolute;
    right: -10px;
    top: -10px;
    cursor: pointer;
`;

const TopText = styled.div`
    font-size: ${fontSize[13]};
    color: ${colorStack.label};
    margin: 0 0 1rem 0;
    padding: 1rem 1rem 0 1rem;
`;

const FormRow = styled.div`
    display: flex;
    margin: 0 0 1rem 0;
    padding: 0 1rem;
    flex-direction: column;
`;

const AddElem = styled.div`
    padding: 12px 1rem;
    height: 56px;
    background: ${colorStack.bodyBg};
    cursor: pointer;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colorStack.middleGrey};
    gap: 1rem;
    button {
        margin: 0 0 0 auto;
    }
`;

const Icon = styled.div`
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 100%;
    background: ${colorStack.lightBlue};
    font-size: 1rem;
`;

const Texts = styled.div`
    display: flex;
    flex-direction: column;
    span {
        ${fontSizeAndHeight[16]};
        &:first-child {
            font-weight: 600;
        }
    }
`;

interface IProps {
    isVisible: boolean;
    initialData?: {
        name: string;
    }
    closeHandler();
    returnData(e: React.MouseEvent<HTMLButtonElement>, elem: IValue, isRemove?: boolean);
}

enum EReferentialsAliasType {
    StakeAliasTypeRcs = 'Stake.AliasType.Rcs'
}

export const NewCompanyForm: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const addAlert = useAlert();
    const [name, setName] = useState<string>('');
    const [addressObj, setAddressObj] = useState<IAddressGlobal>(undefined);
    const [entityLegalForm, setEntityLegalForm] = useState<IReferencial[]>(undefined);
    const [aliasType, setAliasType] = useState<IReferencial[]>(undefined);
    const [legal, setLegal] = useState<IReferencial>(undefined);
    const [rcs, setRcs] = useState<string>(undefined);
    const [creatingCompany, setCreatingCompany] = useState<boolean>(false);
    const [legalobj, setLegalObj] = useState<{ id: string, Name: string, IsDeleted: boolean }>(undefined);
    const [isUseInitialName, setIsUseInitialName] = useState<boolean>(false);
    const [outsideScreen, setOutsideScreen] = useState<number>(0);
    // tslint:disable-next-line:no-null-keyword
    const wrapperRef = useRef<HTMLDivElement>(null);

    const FORM_MARGIN_TOP = 50;

    useEffect(() => {
        !isUseInitialName && props.initialData.name && setName(props.initialData.name);
    }, [props.initialData.name, isUseInitialName]);

    useEffect(() => {
        if (legal) {
            setLegalObj({
                id: legal.id,
                Name: legal.name,
                IsDeleted: false
            });
        } else {
            setLegalObj(undefined);
        }
    }, [legal]);

    useEffect(() => {
        if ((window.devicePixelRatio === 1 || window.location.pathname === '/registration-final-step') && props.isVisible && (window.innerHeight - (wrapperRef.current?.getBoundingClientRect().height - FORM_MARGIN_TOP) - wrapperRef.current?.previousElementSibling.getBoundingClientRect().height < wrapperRef.current?.getBoundingClientRect().top)) {
            setOutsideScreen(window.innerHeight - wrapperRef.current?.parentElement?.getBoundingClientRect().top);
        }
    }, [wrapperRef.current]);

    useEffect(() => {
        dispatch<GetReferentials>(getReferentials('', EReferentials.LegalForm, EContextList.LegalEntity)).then(response => {
            setEntityLegalForm(response);
        });
        dispatch<GetReferentials>(getReferentials('', EReferentials.AliasType, EContextList.Corporate)).then(response => {
            setAliasType(response);
        });
    }, []);

    useEffect(() => {
        if (!props.isVisible) {
            setName('');
            setAddressObj(undefined);
            setLegal(undefined);
            setRcs('');
            setIsUseInitialName(false);
            setAliasType(undefined);
            setEntityLegalForm(undefined);
            setCreatingCompany(false);
            setLegalObj(undefined);
        } else {
            dispatch<GetReferentials>(getReferentials('', EReferentials.LegalForm, EContextList.LegalEntity)).then(response => {
                setEntityLegalForm(response);
            });
            dispatch<GetReferentials>(getReferentials('', EReferentials.AliasType, EContextList.Corporate)).then(response => {
                setAliasType(response);
            });
        }
    }, [props.isVisible]);

    const changeEntityRcsValue = (value: string) => {
        setRcs(value);
    };

    const createNewCompany = useCallback(() => {
        setCreatingCompany(true);
        const newEntity = {
            name: name,
            address: addressObj,
            registrations: [{
                aliasType: aliasType?.find(e => e.Key === EReferentialsAliasType.StakeAliasTypeRcs),
                aliasValue: rcs,
                date: new Date()
            }],
            legalForm: legalobj
        };
        dispatch<NewUpdateCompany>(newUpdateCompany(newEntity)).then(res => {
            props.returnData(undefined, {
                key: res.id,
                text: res.name,
                data: { ...res, avatarType: EProfileType.Company, type: EProfileType.Company }
            });
            addAlert(<IntlMessage id="searchInput.alert.company" />, EAlertType.SUCCESS);
            setCreatingCompany(false);
            props.closeHandler();
        });
    }, [props.returnData, name, addressObj, rcs, aliasType]);

    const changeAddress = useCallback((value: IAddressObject | string) => {
        const valueObject = typeof value === 'string' ? ({
            number: '',
            street: '',
            city: value,
            state: '',
            country: '',
            countryShort: '',
            zipCode: ''
        }) : ({
            number: value?.street_number || '',
            street: (value?.route || value?.neighborhood) || '',
            city: value?.locality || '',
            state: value?.administrative_area_level_1 || '',
            country: value?.country || '',
            countryShort: addressObj.CountryShort || '',
            zipCode: value?.postal_code || ''
        });
        setAddressObj(valueObject);
    }, [addressObj]);

    const selectAddress = useCallback((address: any) => {
        geocodeByAddress(address)
            .then(results => {
                const addressObject: IAddressObject = {};
                const componentForm = {
                    street_number: 'short_name',
                    route: 'long_name',
                    neighborhood: 'long_name',
                    locality: 'long_name',
                    administrative_area_level_1: 'short_name',
                    country: 'long_name',
                    postal_code: 'short_name'
                };
                for (const value of results[0].address_components) {
                    let addressType = value.types[0];
                    if (componentForm[addressType]) {
                        if (addressType === 'country') {
                            setAddressObj(currentState => ({
                                ...currentState,
                                CountryShort: value.short_name
                            }));
                        }
                        addressObject[addressType] = value[componentForm[addressType]];
                    }
                }
                changeAddress(addressObject);
            });

    }, [changeAddress, addressObj]);

    const changeName = (value: string) => {
        setName(value);
        setIsUseInitialName(true);
    };

    const changeSelect = (el: IReferencial) => {
        setLegal(el);
    };

    return (
        <>
            {props.isVisible &&
                <Wrapper ref={wrapperRef} outsideScreen={outsideScreen} width={wrapperRef.current?.parentElement.getBoundingClientRect().width}>
                    <AddElem>
                        <Icon>
                            <FontAwesomeIcon icon={faEnvelope} />
                        </Icon>
                        <Texts>
                            <IntlMessage id="global.leDoesntExist" tagName="span" />
                            <span>{name}</span>
                        </Texts>
                        <Button onClick={createNewCompany} disabled={!name || creatingCompany} type="button"><IntlMessage id="global.create" /></Button>
                    </AddElem>
                    <CloseForm onClick={props.closeHandler} width={24} height={24} />
                    <TopText><IntlMessage id="searchInput.topText.company" /></TopText>
                    <FormRow>
                        <TextComponent
                            label={<IntlMessage id="searchInput.form.leName" />}
                            required
                            value={name}
                            onChange={changeName}
                        />
                    </FormRow>
                    <FormRow>
                        <LabelWithEllipsis>
                            <IntlMessage id="searchInput.form.leAddress" />
                        </LabelWithEllipsis>
                        <GoogleAddressEditor
                            onChangeAddress={(newValue: string) => changeAddress(newValue.replace(/^\s+/g, ''))}
                            onSelectAddress={selectAddress}
                            address={addressObj}
                        />
                    </FormRow>
                    <FormRow>
                        <LabelWithEllipsis>
                            <IntlMessage id="searchInput.form.legalForm" />
                        </LabelWithEllipsis>
                        <SelectInput
                            value={legal?.id ? [{ key: legal?.id, text: legal?.name, data: legal }] : undefined}
                            onChange={(option: IValue) => changeSelect(option?.data)}
                            options={entityLegalForm?.map((elem) => ({
                                key: elem.id,
                                text: elem.name,
                                data: elem
                            }))}
                            type={EDropDownType.DEFAULT}
                        />
                    </FormRow>
                    <FormRow>
                        <TextComponent
                            label={<IntlMessage id="searchInput.form.rcs" />}
                            value={rcs}
                            onChange={changeEntityRcsValue}
                        />
                    </FormRow>
                </Wrapper>
            }
        </>
    );
};
