import React, { useCallback, useState, FC } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Input } from '../../../Common/Input/Input';
import { createNewCluster } from '../../../../actions/clustersActions';
import { IClusterTemplate, EClusterPrivacy } from '../../../../entities/IClusters';
import { useOrganization } from '../../../../tools/organizationHooks';
import { fontSize } from '../../../../styleHelpers/fontSizes';
import { SelectInput } from '../../../Common/SelectInput/SelectInput';
import { ConfirmPopup } from '../../../Common/ConfirmPopup/ConfirmPopup';
import { EPopupSizes } from '../../../../entities/IGlobal';
import { IValue } from '../../../../entities/IPickers';
import { EDropDownType } from '../../../Common/DropDown/DropDown';
import IntlMessage, { useIntlMessage } from '../../../Common/IntlMessage';

const CreationWrapper = styled.div`
    padding: .5rem;
`;

const ClusterTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    > span {
        font-size: ${fontSize[20]};
        font-weight: 500;
    }
`;

const ClusterName = styled.div`
    margin: .5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;

const ClusterType = styled.div``;

const Label = styled.label`
    font-size: ${fontSize[13]};
    margin: 0 0 5px 0;
`;

interface IClusterForm {
    title: string;
    description: string;
    type: EClusterPrivacy;
}

interface IProps {
    selectedTemplate: IClusterTemplate;
    outsideConfirm?: boolean;
    clusterForm: IClusterForm;
    setSelectedTemplate(template: IClusterTemplate);
    setClusterForm(clusterForm: IClusterForm);
}

export const CreateCluster: FC<React.PropsWithChildren<IProps>> = props => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const { intlFormatMessage } = useIntlMessage();
    const { organization } = useParams<{ organization: string; }>();
    const [creatingCluster, setCreatingCluster] = useState<boolean>(false);
    const [clusterNameError, setClusterNameError] = useState<string>(undefined);
    const { currentOrganization } = useOrganization(organization);
    const privacyTranslation = {
        [EClusterPrivacy.Private]: intlFormatMessage({ id: 'cluster.privacy.private' }),
        [EClusterPrivacy.Public]: intlFormatMessage({ id: 'cluster.privacy.public' }),
        [EClusterPrivacy.Secret]: intlFormatMessage({ id: 'cluster.privacy.secret' })
    };

    const clearSelectedTemplate = useCallback((clearParams?: boolean) => {
        props.setSelectedTemplate(undefined);
        props.setClusterForm(undefined);
        if (clearParams) {
            history({
                search: ``
            });
        }
    }, []);

    const onSubmit = useCallback(() => {
        setCreatingCluster(true);
        dispatch(createNewCluster({
            organizationId: currentOrganization?.id,
            name: props.clusterForm.title,
            description: props.clusterForm.description,
            privacy: props.clusterForm.type || EClusterPrivacy.Private,
            organizationUrlName: currentOrganization?.details?.urlName,
            organizationName: currentOrganization?.details?.name,
            clusterTemplateId: props.selectedTemplate.id
        }))
            .then(response => {
                clearSelectedTemplate();
                setCreatingCluster(false);
                history(`/orgs/${currentOrganization?.details?.urlName}/cluster/${response.id}`);
            }).catch((err) => {
                if (err.data.errorCode === 2250) {
                    setCreatingCluster(false);
                    setClusterNameError(err.data.message);
                }
            });
    }, [props.clusterForm, currentOrganization?.id, props.selectedTemplate?.id, currentOrganization]);

    const onNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string, selectedCurrency?: IValue) => {
        e.persist();
        const newValue = value || '';
        props.setClusterForm({
            ...props.clusterForm,
            title: newValue
        });
    }, [props.setClusterForm, props.clusterForm]);

    const onTypeChange = useCallback((item: IValue) => {
        props.setClusterForm({
            ...props.clusterForm,
            type: item.key as EClusterPrivacy
        });
    }, [props.setClusterForm, props.clusterForm]);

    return (
        <ConfirmPopup
            showPopup={!!props.selectedTemplate}
            noHandler={() => clearSelectedTemplate(true)}
            size={EPopupSizes.SMALL}
            yesHandler={onSubmit}
            loading={creatingCluster}
            yesButtonText={<IntlMessage id="global.create" />}
            noButtonText={<IntlMessage id="global.cancel" />}
        >
            <CreationWrapper>
                <ClusterTitle>
                    <span>{props.selectedTemplate?.templateName}</span>
                </ClusterTitle>
                <ClusterName>
                    <Input
                        value={props.clusterForm?.title}
                        label={<IntlMessage id="cluster.label.name" />}
                        required
                        error={(typeof props.clusterForm?.title !== 'undefined' && !props.clusterForm?.title) || clusterNameError || props.clusterForm?.title?.length > 100}
                        errorContent={clusterNameError || props.clusterForm?.title?.length > 100 ? intlFormatMessage({ id: 'cluster.error.title' }) : intlFormatMessage({ id: 'cluster.error.fieldIsRequired' })}
                        onChange={onNameChange}
                    />
                </ClusterName>
                <ClusterType>
                    <Label>
                        <IntlMessage id="cluster.label.confidentiality" />
                    </Label>
                    <SelectInput
                        value={[{ key: props.clusterForm?.type || EClusterPrivacy.Private, text: props.clusterForm?.type || EClusterPrivacy.Private, data: props.clusterForm?.type || EClusterPrivacy.Private }]}
                        onChange={onTypeChange}
                        options={Object.keys(EClusterPrivacy).map(key => ({
                            key,
                            text: privacyTranslation[key],
                            data: key
                        }))}
                        type={EDropDownType.DEFAULT}
                    />
                </ClusterType>
            </CreationWrapper>
        </ConfirmPopup>
    );
};
