import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { EApprovalContext, EApprovalTypes, IEApprovalContext, IMainStepProps } from '..';
import { Row } from '../../../../Common/Row/Row';
import { ETextComponentSubType, TextComponent } from '../../../../Common/Inputs/TextComponent';
import { SelectInput } from '../../../../Common/SelectInput/SelectInput';
import { IValue } from '../../../../../entities/IPickers';
import { EDropDownType } from '../../../../Common/DropDown/DropDown';
import { getReferentials } from '../../../../../actions/globalActions';
import { DEFAULT_CURRENCY_KEY, EContextList, EReferentials, IReferencial } from '../../../../../entities/IGlobal';
import { LegalEntitiesPicker } from '../../../../Common/Pickers/LegalEntitiesPicker';
import { ISIngleLegalEntity } from '../../../../../entities/ILegalEntities';
import { RadioButton } from '../../../../Common/Inputs/RadioButton';
import { LabelWithEllipsis } from '../../../../Common/Label/Label';
import { NewRT } from '../../../../Common/RichText/NewRT';
import IntlMessage, { useIntlMessage } from '../../../../Common/IntlMessage';

type GetReferentials = ReturnType<typeof getReferentials>;

const Wrapper = styled.div`
    width: 100%;
    > div {
        margin-bottom: 1rem;
    }
    > :last-child {
        margin: 0;
    }
`;

const RadioInputSection = styled.div`
    display: flex;
    label {
        margin-right: 29px;
    }
`;

export const DeclarationSponsoring: FC<IMainStepProps> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const dispatch = useDispatch();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);
    const [typeOfSponsoringProjectOptions, setTypeOfSponsoringProjectOptions] = useState<IReferencial[]>(undefined);
    const [originOfSponsoringProjectOptions, setOriginOfSponsoringProjectOptions] = useState<IReferencial[]>(undefined);
    const [unitItems, setUnitItems] = useState<IReferencial[]>(undefined);

    useEffect(() => {
        dispatch<GetReferentials>(getReferentials('', EReferentials.TypeOfSponsoringProject, EContextList.EApproval)).then(response => {
            setTypeOfSponsoringProjectOptions(response);
        });
        dispatch<GetReferentials>(getReferentials('', EReferentials.OriginOfSponsoringProject, EContextList.EApproval)).then(response => {
            setOriginOfSponsoringProjectOptions(response);
        });
        dispatch<GetReferentials>(getReferentials('', EReferentials.Currency, EContextList.Platform)).then(response => {
            setUnitItems(response);
        });
        eApprovalContext.modifyData(undefined, undefined, props.context, props.stepName, props.stepNumber);
    }, []);

    useEffect(() => {
        const defaultCurrency = unitItems?.find((elem) => elem.Key === DEFAULT_CURRENCY_KEY);
        if (!eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration && unitItems) {
            eApprovalContext.modifyData({
                number: undefined,
                // tslint:disable-next-line:no-null-keyword
                baseNumber: null,
                unit: {
                    key: defaultCurrency?.id,
                    parentId: defaultCurrency?.referentialIdentityId,
                    uniqueKey: defaultCurrency?.Key
                }
            }, 'amountOfContribution', props.context, props.stepName, props.stepNumber);
        }
    }, [unitItems]);

    const onReferentialChange = (field: string, option: IValue) => {
        eApprovalContext.modifyData(option?.data, field, props.context, props.stepName, props.stepNumber);
    };

    const onTextChange = (field: string, value: string) => {
        eApprovalContext.modifyData(value, field, props.context, props.stepName, props.stepNumber);
    };

    const onChangeBeneficiary = (element: { key: string; text: string; data: ISIngleLegalEntity; }[]) => {
        eApprovalContext.modifyData(element?.[0]?.data, 'beneficiary', props.context, props.stepName, props.stepNumber);
    };

    const onAOFChange = (value: boolean) => {
        eApprovalContext.modifyData(value, 'existAOF', props.context, props.stepName, props.stepNumber);
    };

    const onContractDescriptionChange = (value: string) => {
        if (value !== eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.[props.stepName]?.contractDescription) {
            const span = document.createElement('span');
            span.innerHTML = value;
            if (span.innerText?.trim()?.length > 0) {
                eApprovalContext.modifyData(value, 'contractDescription', props.context, props.stepName, props.stepNumber);
            } else if (span.innerText?.trim()?.length === 0) {
                eApprovalContext.modifyData('', 'contractDescription', props.context, props.stepName, props.stepNumber);
            }
        }
    };

    const onCurrencyChange = (field: string, valueText: string, unit: IValue) => {
        const value = valueText.replace(',', '.').replaceAll(' ', '');
        if (/^\d+\.?\d{0,6}$/.test(value) || value === '') {
            eApprovalContext.modifyData({
                number: valueText,
                // tslint:disable-next-line:no-null-keyword
                baseNumber: null,
                unit: {
                    key: unit?.data.key,
                    parentId: unit?.data.parentId || unit?.data.referentialIdentityId,
                    uniqueKey: unit?.data.uniqueKey || unit?.data.Key
                }
            }, field, props.context, props.stepName, props.stepNumber);
        }
    };

    return (
        <Wrapper>
            <Row numberOfElements={2} noBorder>
                <SelectInput
                    value={eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.typeOfProject?.id ?
                        [{
                            key: eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.typeOfProject?.id,
                            text: eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.typeOfProject?.name,
                            data: eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.typeOfProject
                        }] : undefined}
                    onChange={(value) => onReferentialChange('typeOfProject', value)}
                    inputLabel={<IntlMessage id={`eApproval.wizard.step2.${[EApprovalTypes.Sponsoring]}.typeOfProject`} />}
                    required
                    options={typeOfSponsoringProjectOptions?.map(elem => ({
                        key: elem.id,
                        text: elem.name,
                        data: elem
                    }))}
                    type={EDropDownType.DEFAULT}
                />
                <TextComponent
                    value={eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.nameOfProject}
                    label={intlFormatMessage({ id: `eApproval.wizard.step2.${[EApprovalTypes.Sponsoring]}.nameOfProject` })}
                    onChange={(value) => onTextChange('nameOfProject', value)}
                    required
                />
            </Row>
            <Row numberOfElements={2} noBorder>
                <SelectInput
                    value={eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.originOfProject?.id ?
                        [{
                            key: eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.originOfProject?.id,
                            text: eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.originOfProject?.name,
                            data: eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.originOfProject
                        }] : undefined}
                    onChange={(value) => onReferentialChange('originOfProject', value)}
                    inputLabel={<IntlMessage id={`eApproval.wizard.step2.${[EApprovalTypes.Sponsoring]}.originOfProject`} />}
                    required
                    options={originOfSponsoringProjectOptions?.map(elem => ({
                        key: elem.id,
                        text: elem.name,
                        data: elem
                    }))}
                    type={EDropDownType.DEFAULT}
                />
                <TextComponent
                    value={eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.agencyCentre}
                    label={intlFormatMessage({ id: `eApproval.wizard.step2.${[EApprovalTypes.Sponsoring]}.agencyCentre` })}
                    onChange={(value) => onTextChange('agencyCentre', value)}
                />
            </Row>
            <Row numberOfElements={2} noBorder>
                <LegalEntitiesPicker
                    label={<IntlMessage id={`eApproval.wizard.step2.${[EApprovalTypes.Sponsoring]}.beneficiary`} />}
                    value={eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.beneficiary?.id ? [{
                        key: eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.beneficiary?.id,
                        text: eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.beneficiary?.name,
                        data: eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.beneficiary
                    }] : undefined}
                    allCompanies
                    required
                    orgId={eApprovalContext.orgId}
                    onSelectElement={onChangeBeneficiary}
                />
                {unitItems?.length > 0 &&
                    <TextComponent
                        label={intlFormatMessage({ id: `eApproval.wizard.step2.${[EApprovalTypes.Sponsoring]}.amountOfContribution` })}
                        required
                        value={eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.amountOfContribution?.number}
                        onChange={(valueText, unit) => onCurrencyChange('amountOfContribution', valueText, unit)}
                        subType={ETextComponentSubType.CURRENCY}
                        currencies={unitItems?.sort((a, b) => a.name.localeCompare(b.name))}
                        selectedCurrency={(eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.[props.stepName]?.amountOfContribution?.unit?.uniqueKey && unitItems) ? {
                            key: eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.[props.stepName]?.amountOfContribution?.unit?.uniqueKey,
                            text: unitItems?.find(elem => elem.Key === eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.[props.stepName]?.amountOfContribution?.unit?.uniqueKey)?.name,
                            data: eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.amountOfContribution?.unit
                        } : undefined}
                    />
                }
            </Row>
            <Row numberOfElements={1} noBorder>
                <div>
                    <LabelWithEllipsis numberOfLines={3} required tooltipTitle={intlFormatMessage({ id: `eApproval.wizard.step2.eapproval-sponsoring.tooltip` })}>
                        <IntlMessage id={`eApproval.wizard.step2.${[EApprovalTypes.Sponsoring]}.existAOF`} />
                    </LabelWithEllipsis>
                    <RadioInputSection>
                        <RadioButton
                            label={intlFormatMessage({ id: 'global.yes' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.existAOF === true}
                            onClick={() => onAOFChange(true)}
                        />
                        <RadioButton
                            label={intlFormatMessage({ id: 'global.no' })}
                            checked={eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.existAOF === false}
                            onClick={() => onAOFChange(false)}
                        />
                    </RadioInputSection>
                </div>
            </Row>
            {eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.existAOF &&
                <Row numberOfElements={1} noBorder>
                    <TextComponent
                        value={eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.descriptionAOF}
                        label={intlFormatMessage({ id: `eApproval.wizard.step2.${eApprovalContext.type}.descriptionAOF` })}
                        onChange={(value) => onTextChange('descriptionAOF', value)}
                        required
                    />
                </Row>
            }
            <Row numberOfElements={1} noBorder>
                <div>
                    <NewRT
                        label={intlFormatMessage({ id: `eApproval.wizard.step2.${eApprovalContext.type}.descriptionContract` })}
                        required
                        content={eApprovalContext.data?.[EApprovalTypes.Sponsoring]?.declaration?.contractDescription}
                        onChange={onContractDescriptionChange}
                    />
                </div>
            </Row>
        </Wrapper>
    );
};
