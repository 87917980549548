import React, { FC, useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { faPen } from '@fortawesome/pro-regular-svg-icons';

import { updateBranchDescription } from '../../../actions/sogeActions';
import { ISogeReducer } from '../../../reducers/sogeReducer';
import { IState } from '../../../reducers';
import { IBranchWithContents } from '../../../entities/ISoge';
import { fontSize } from '../../../styleHelpers/fontSizes';
import { Button, EButtonTypeSchema } from '../../Common/Buttons/NewButton';
import { NewRT } from '../../Common/RichText/NewRT';
import { NewRtView } from '../../Common/RichText/NewRtView';
import IntlMessage from '../../Common/IntlMessage';

const Description = styled.div`
    display: flex;
    flex-direction: column;
`;

const DescriptionHeader = styled.div`
    display: flex;
    align-items: center;
    > span {
        font-size: ${fontSize[13]};
    }
    > button {
        margin-left: 1rem;
    }
`;

const ButtonGroup = styled.div`
    margin-left: 1rem;
    > button {
        margin: 5px;
    }
`;

const RichTextWrapper = styled.div`
    margin: 15px 0;
`;

interface IBranchDescriptionProps {
    branch: IBranchWithContents;
    versionId: string;
    onSaveDescription(content: string);
}

export const BranchDescription: FC<React.PropsWithChildren<IBranchDescriptionProps>> = ({ branch, versionId, onSaveDescription }) => {
    const { documentationId } = useSelector<IState, ISogeReducer>(state => state.soge);
    const dispatch = useDispatch();
    const [descContent, setDescContent] = useState<string>('');
    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    useEffect(() => {
        setDescContent(branch?.description || '');
    }, [branch?.description]);

    const onDescChange = useCallback((value: string) => {
        setDescContent(value);
    }, []);

    const onEditModeChange = useCallback(() => {
        setIsEditMode(editMode => !editMode);
    }, []);

    const onSaveDescClick = useCallback(() => {
        setIsEditMode(false);
        dispatch(updateBranchDescription(documentationId, versionId, branch.id, branch.lcid, descContent, branch.isMinorChange)).then(response => {
            onSaveDescription?.(response?.description || '');
        });
    }, [descContent, documentationId, versionId, branch?.id, onSaveDescription]);

    const onCancelDescEdit = useCallback(() => {
        setDescContent(branch?.description || '');
        setIsEditMode(false);
    }, [branch?.description]);

    return (
        <Description>
            <DescriptionHeader>
                <IntlMessage id="tempid"  />
                {isEditMode ? (
                    <ButtonGroup>
                        <Button typeSchema={EButtonTypeSchema.TEXT} onClick={onCancelDescEdit}>
                            <IntlMessage id="global.cancel"  />
                        </Button>
                        <Button onClick={onSaveDescClick}>
                            <IntlMessage id="global.save"  />
                        </Button>
                    </ButtonGroup>
                ) : (
                    <>
                        {branch && (
                            <Button typeSchema={EButtonTypeSchema.TEXT} onClick={onEditModeChange} leftIco={faPen} type="button">
                                <IntlMessage id="global.edit"  />
                            </Button>
                        )}
                    </>
                )}
            </DescriptionHeader>
            <RichTextWrapper>
                {isEditMode ? (
                    <>
                        {branch && (
                            <NewRT
                                content={descContent}
                                onChange={onDescChange}
                            />
                        )}
                    </>
                ) : (
                    <NewRtView content={descContent} />
                )}
            </RichTextWrapper>
        </Description>
    );
};
