import React, { FC, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';

import { EApprovalContext, EApprovalTypes, IEApprovalContext, IMainStepProps } from '..';
import { BadgeWrapper, GreyBox, PrinciplesContainer } from '../common';
import { Row } from '../../../../Common/Row/Row';
import { SelectInput } from '../../../../Common/SelectInput/SelectInput';
import { EDropDownType } from '../../../../Common/DropDown/DropDown';
import { IValue } from '../../../../../entities/IPickers';
import { UsersPicker } from '../../../../Common/Pickers/UsersPicker';
import { IUserBrief } from '../../../../../entities/IGlobal';
import { getTypesWithStatuses } from '../../../../../actions/validationActions';
import { ITypesWithStatuses } from '../../../../../entities/IValidation';
import { Button, EButtonTypeSchema } from '../../../../Common/Buttons/NewButton';
import { Badge, EBadgeColor, EBadgeShape, EBadgeSize } from '../../../../Common/Badge/Badge';
import { Checkbox } from '../../../../Common/Inputs/Checkbox';
import { Order } from '../../../ClustersV2/ClusterDocuments/ApprovalPopup/Step1';
import IntlMessage, { useIntlMessage } from '../../../../Common/IntlMessage';

type GetTypesWithStatuses = ReturnType<typeof getTypesWithStatuses>;

const Wrapper = styled.div`
    width: 100%;
`;

const CheckBoxContainer = styled.div`
    margin-bottom: 1rem;
`;

const SingleApproverContainer = styled.div`
    display: flex;
    align-items: center;
    >:last-child {
        width: 90%;
    }
`;

export const Step5: FC<IMainStepProps> = props => {
    const { intlFormatMessage } = useIntlMessage();
    const dispatch = useDispatch();
    const eApprovalContext = useContext<IEApprovalContext>(EApprovalContext);
    const [statuses, setStatuses] = useState<ITypesWithStatuses[]>(undefined);

    useEffect(() => {
        eApprovalContext.modifyData(eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.approvers?.[0]?.approver || {}, 'approver', props.context, props.stepName, props.stepNumber, 0);
    }, []);

    useEffect(() => {
        dispatch<GetTypesWithStatuses>(getTypesWithStatuses()).then(response => {
            setStatuses(response);
        });
    }, []);

    const onChangeStatus = (option: IValue, index: number) => {
        eApprovalContext.modifyData({type: option?.data.type, name: option?.text}, 'status', props.context, props.stepName, props.stepNumber, index);
    };

    const onChangeManager = (element: { key: string; text: string; data: IUserBrief; }[], index: number) => {
        eApprovalContext.modifyData(element?.[0]?.data, 'approver', props.context, props.stepName, props.stepNumber, index);
    };

    const onAddItem = () => {
        eApprovalContext.modifyData({}, 'approver', props.context, props.stepName, props.stepNumber, eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.approvers?.length);
    };

    const removeElement = (index: number) => {
        eApprovalContext.removeFromArrays(props.context, props.stepName, index);
    };

    const changeCheckbox = (checked: boolean) => {
        eApprovalContext.modifyData(checked, '', props.context, 'approversOrderCheckbox', props.stepNumber);
        if (!checked) {
            eApprovalContext.modifyData(eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.approvers?.map(elem => ({ ...elem, stageId: undefined })), '', props.context, props.stepName, props.stepNumber);
        }
    };

    const onOrderChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const order = e.target.value;
        eApprovalContext.modifyData(Number(order) > 0 ? Number(order) : undefined, 'stageId', props.context, props.stepName, props.stepNumber, index);
    };

    return (
        <Wrapper>
            <PrinciplesContainer>
                <div dangerouslySetInnerHTML={{
                    __html: intlFormatMessage({ id: `eApproval.wizard.step5.approvers.free-declaration` })
                }} />
            </PrinciplesContainer>
            <CheckBoxContainer>
                <Checkbox onChange={changeCheckbox} checked={eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.approversOrderCheckbox} disabled={eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.approvers?.length < 2} label={intlFormatMessage({ id: 'approval.button.order' })} />
            </CheckBoxContainer>
            {eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.approvers?.map((elem, index) =>
                <SingleApproverContainer key={index}>
                    {eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.approversOrderCheckbox &&
                        <Order isStageNumberEmpty={!elem.stageId}>
                            <input type="number" onChange={(e) => onOrderChange(e, index)} value={elem.stageId} />
                        </Order>
                    }
                    <GreyBox>
                        {index > 0 &&
                            <BadgeWrapper>
                                <Badge icon={faTrash} shape={EBadgeShape.CIRCLE} color={EBadgeColor.RED} size={EBadgeSize.SMALL} onClick={() => removeElement(index)} />
                            </BadgeWrapper>
                        }
                        <Row numberOfElements={2} noBorder>
                            <UsersPicker
                                value={elem.approver?.id ? [{
                                    key: elem.approver?.id,
                                    text: `${elem.approver?.firstName}
                                        ${elem.approver?.lastName}`,
                                    data: elem.approver
                                }] : undefined}
                                onSelectElement={value => onChangeManager(value, index)}
                                required
                                label={<IntlMessage id={`eApproval.wizard.approvers.approver`} />}
                            />
                            <SelectInput
                                value={elem.status?.type ? [{
                                    key: elem.status?.type,
                                    text: elem.status?.name,
                                    data: elem.status
                                }] : undefined}
                                required
                                onChange={(option) => onChangeStatus(option, index)}
                                inputLabel={<IntlMessage id={'eApproval.wizard.approvers.status'} />}
                                options={statuses?.map(status => ({
                                    key: status.type,
                                    text: status.translationKey ? intlFormatMessage({ id: status.translationKey }) : '',
                                    data: status
                                }))}
                                type={EDropDownType.DEFAULT}
                            />
                        </Row>
                    </GreyBox>
                </SingleApproverContainer>
            )}
            <Button type="button"
                typeSchema={EButtonTypeSchema.TEXT}
                disabled={(eApprovalContext.data?.[EApprovalTypes.FreeDeclaration]?.approvers || [])?.map(elem => (!!elem?.approver?.firstName && !!elem.status)).includes(false)}
                onClick={onAddItem}
                leftIco={faPlus}
            >
                <IntlMessage id="eApproval.button.add-approver" />
            </Button>
        </Wrapper>
    );
};